import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Typography, makeStyles, Paper } from '@material-ui/core';
import { AuthenticateUser, FetchUserData, ReAuthenticate, LogOut, GetDashboardCounts, GetDashboardReferringUrl, GetDashboardData, GetChatHistory, GetPDFData, GetDashboardReports, GetLocation, ForgotPassword, SendResetCode, ResetPassword } from './services/DashboardServiceList';
 
//import Login from './components/Login';
import PageHeader from './components/Header';
//import StatsCard from './components/StatsCard';
//import GridComponent from './components/Grid';
import PageFooter from './components/PageFooter';
//import SendEmailPage from './pages/reset-password/SendEmailPage';
//import VerifyCodePage from './pages/reset-password/VerifyCodePage';
//import ConfirmNewPasswordPage from './pages/reset-password/ConfirmNewPasswordPage';
import * as FileSaver from 'file-saver';

// Lazy load components
const Login = lazy(() => import('./components/Login'));
const SendEmailPage = lazy(() => import('./pages/reset-password/SendEmailPage'));
const VerifyCodePage = lazy(() => import('./pages/reset-password/VerifyCodePage'));
const ConfirmNewPasswordPage = lazy(() => import('./pages/reset-password/ConfirmNewPasswordPage'));
const StatsCard = lazy(() => import('./components/StatsCard'));
const GridComponent = lazy(() => import('./components/Grid'));

interface Address {
  streetLine1: string;
  streetLine2: string;
  city: string;
  state: string;
  county: string;
  postalCode: string;
  country: string;
}

interface PartnerMapping {
  partnerId: string;
  partnerName: string;
  isPartnerAdmin: boolean;
}

interface User {
  id: string;
  userStatus: string;
  createdOn: string;
  firstName: string;
  lastName: string;
  fullName: string;
  mobileNumber: string;
  email: string;
  password: string;
  address: Address;
  allowAddressEntry: boolean;
  agentTitle: string;
  avatarUrl: string;
  timeZone: string;
  partnerMappings: PartnerMapping[];
}

interface DashboardDataItem {
  sessionId: string;
  sessionNumber: number;
  firstName: string;
  lastName: string;
  createdOn: string;
  petOwnerName: string;
  petOwnerId: string;
  petName: string;
  speciesName: string;
  breedName: string;
  openingQuery: string;
  vetNotes: string;
  chatType: string;
  referringUrl:string;
  location: string;
}

interface ChatHistoryData {
  speciesName: string;
  petBreed: string;
  petGender: string;
  petDateOfBirth: string;
  isSpayedOrNeutered: boolean;
  lastAssignedToAgent: string | null;
  participants: Participant[];
  posts: Post[];
  files: any[];
  feedbackRating: number | null;
  feedbackComment: string | null;
  petOwnerRecommendations: string | null;
  name: string;
  feedbackDate: string | null;
  intakeAnswers: any[];
  featureId: string;
  id: string;
  createdOn: string;
  petId: string;
  petName: string;
  intakeQuestion: string;
  commType: string;
  hasRating: boolean;
  source: string;
  subject: string;
  isPetOwnerAnonymized: boolean;
  petOwnerName: string;
  senderDisplayName: string;
  senderFullName: string | null;
  senderPhoneNumber: string;
  petAvatarUrl: string | null;
  textPreview: string | null;
  sentOn: string;
  entryType: string;
  entryId: string;
  rating: number | null;
  senderParticipantType: string | null;
  messageStatus: string | null;
}

interface Participant {
  id: string;
  userId: string;
  type: string;
  salutation: string | null;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string | null;
  agentDisplayName: string | null;
  agentTitle: string | null;
}

interface Post {
  timestamp: string;
  participantId: string;
  text: string | null;
  files: any[];
  postType: string;
  newQueue: string | null;
}

type ServerError = string | null;

const useStyles = makeStyles((theme) => ({
    appContainer: {
      alignItems: 'center',
      backgroundColor: '#063056',
      backgroundSize: 'cover',
      height: '100vh',
      maxWidth: '2000px',
      margin: 'auto',
    },
    error: {
      color: 'rgb(235, 34, 77)',
    },
}));


const Dashboard: React.FC = () => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfDownloading, setPDFDownloading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [todaysSummaryLoading, setTodaysSummaryLoading] = useState(false);
  const [referringUrlLoading, setReferringLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [error, setError] = useState('');
  const [dashboardData, setDashboardData] = useState<DashboardDataItem[]>([]);
  const [locationsCheckboxList, setLocationsCheckboxList] = useState<[]>([]);

  const [chatData, setChatData] = useState<ChatHistoryData | null>(null);
  const [selectedCheckboxData, setSelectedCheckboxData] = useState<string[]>([]);
  const [serverError, setServerError] = useState<ServerError>(null);

  const [resetEmail, setResetEmail] = useState('');
  const [resetCode, setResetCode] = useState('');

  const [resetEmailSuccess, setResetEmailSuccess] = useState(false);
  const [resetCodeSuccess, setResetCodeSuccess] = useState(false);

  const [todaysSummary, setTodaysSummary] = useState<any>({
    newVisitorsToday: 0,
    repeatVisitorsToday: 0,
    totalVeraChatsToday: 0,
    requestedLiveChatToday: 0
  });

  const [summary, setSummary] = useState<any>({
    newVisitorsInDateRange: 0,
    repeatVisitorsInDateRange: 0,
    totalVeraChatsInDateRange: 0,
    requestedLiveChatInDateRange: 0
  });

  const [referringUrls, setReferringUrls] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [user, setUser] = useState<User | null>(null);
  
  const storedPartnerId: any = localStorage.getItem('partnerId');
  const storedPartnerName: any = localStorage.getItem('partnerName');

  const [firstPartnerId, setFirstPartnerId] = useState(storedPartnerId ? storedPartnerId : user?.partnerMappings[0].partnerId || '');
  const [selectedPartnerId, setSelectedPartnerId] = useState(firstPartnerId);
  const [selectedPartnerName, setSelectedPartnerName] = useState(storedPartnerName ? storedPartnerName : user?.partnerMappings[0].partnerName || '');
  const currentDate = new Date();
  const last3MonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, currentDate.getDate());
  const fromDate = last3MonthStart.toISOString();
  const toDate = currentDate.toISOString();

  const [logoutTimeout, setLogoutTimeout] = useState<NodeJS.Timeout | null>(null);

  const initialRequestBody = {
    skip: 0,
    take: 100,
    sorts: [{ sort: 'CreatedOn', isAscending: true }],
    partnerId: firstPartnerId,
    customStartDate: fromDate,
    customEndDate: toDate,
    searchText: '',
    isDateFilter: true,
    dateFilter: 'ThisMonth',
    chatTypes: ['All'],
    locations: ['All Locations'],
    isChatType: true,
  };

  const storedDashboardState: any = localStorage.getItem('dashboardState');
  const parsedDashboardState = storedDashboardState ? JSON.parse(storedDashboardState) : {};

  const storedReportDashboardState: any = localStorage.getItem('reportDataState');
  const parsedReportDashboardState = storedReportDashboardState ? JSON.parse(storedReportDashboardState) : {};

  const storedSummaryState: any = localStorage.getItem('storedSummaryData');
  const parsedSummaryState = storedSummaryState ? JSON.parse(storedSummaryState) : {};

  const storedReferringState: any = localStorage.getItem('storedReferringUrlData');
  const parsedReferringState = storedReferringState ? JSON.parse(storedReferringState) : {};

  const [currentRequestBody, setCurrentRequestBody] = useState<any>(initialRequestBody);

  const updateCurrentRequestBody = (updatedRequestBody: any) => {
    setCurrentRequestBody((previousRequestBody:any) => {
      const newRequestBody = {
        ...previousRequestBody,
        ...updatedRequestBody,
      };
      localStorage.setItem('dashboardState', JSON.stringify(newRequestBody));
      return newRequestBody;
    });
  };

  const handlePartnerChange = (partnerId: any, partnerName: string) => {
    setSearchLoading(true);
    const resetBody = {
      skip: 0,
      take: 100,
      sorts: [{ sort: 'CreatedOn', isAscending: true }],
      partnerId: firstPartnerId,
      customStartDate: fromDate,
      customEndDate: toDate,
      searchText: '',
      isDateFilter: true,
      dateFilter: 'ThisMonth',
      chatTypes: ['All'],
      locations: ['All Locations'],
      isChatType: true,
    };
    setDashboardData([]);
    localStorage.setItem('partnerId', partnerId);
    localStorage.setItem('partnerName', partnerName);

    const dashboardState = storedDashboardState ? JSON.parse(storedDashboardState) : {};
    localStorage.setItem('dashboardState', JSON.stringify(parsedDashboardState.partnerId = partnerId));

    setSelectedPartnerId(partnerId);
    setSelectedPartnerName(partnerName);
    updateCurrentRequestBody({...dashboardState, partnerId: storedPartnerId });
    handleLoadCountData(parsedDashboardState.partnerId, parsedSummaryState.dateFilter, parsedSummaryState.customStartDate, parsedSummaryState.customEndDate); 
    handleLoadReferringUrlData(parsedDashboardState.partnerId, parsedReferringState.dateFilter, parsedReferringState.customStartDate, parsedReferringState.customEndDate);
    if(currentRequestBody.partnerId != partnerId) {
      fetchDashboardData(parsedDashboardState.partnerId, {...parsedDashboardState, partnerId: partnerId, locations: ['All Locations'], skip: 0 }, 0, rowsPerPage);
      localStorage.setItem('dashboardState', JSON.stringify(parsedDashboardState.skip = 0));
      localStorage.setItem('dashboardState', JSON.stringify(parsedDashboardState.locations = ['All Locations']));
      localStorage.setItem('reportDataState', JSON.stringify({...parsedReportDashboardState, locations: ['All Locations']}));
    } else {
      fetchDashboardData(parsedDashboardState.partnerId, {...parsedDashboardState, partnerId: partnerId, skip: parsedDashboardState.skip }, parsedDashboardState.skip, rowsPerPage);
    }
    fetchDashboardLocationsData(parsedDashboardState.partnerId, {...parsedDashboardState, partnerId: partnerId}, parsedDashboardState.skip, rowsPerPage);
  };

  const fetchDashboardData = async (newPartnerId: string | undefined, requestBody: any, pageNum: number, rowsPerPage: number) => {
    const currentPayload = { ...currentRequestBody, ...requestBody, partnerId: newPartnerId ? newPartnerId : firstPartnerId, skip: pageNum, take: rowsPerPage };
    
    // // Retrieve the stored payload from local storage
    // const storedPayload = localStorage.getItem('dashboardState');

    // // If the current payload matches the stored one, skip the API call
    // if (storedPayload && JSON.stringify(currentPayload) === storedPayload) {
    //   console.log(storedPayload, );
    //     console.log("Payload unchanged, skipping API call.");
    //     return;
    // }

    // Update the stored payload with the current one
    localStorage.setItem('dashboardState', JSON.stringify(currentPayload));

    if (newPartnerId || firstPartnerId) {
        setSearchLoading(true);
        updateCurrentRequestBody(currentPayload);
        await fetchDataAndUpdateUI(requestBody);
    }
  };

  const abortControllerRef = useRef<AbortController | null>(null)  

  const fetchDataAndUpdateUI = async (requestBody: any) => {
    abortControllerRef.current?.abort(); 
    abortControllerRef.current = new AbortController();

    setSearchLoading(true);

      const storedUser: any = localStorage.getItem('accessToken');
      const token = JSON.parse(storedUser); 
      if (requestBody.partnerId !== '') {
        try {
          const response: any = await GetDashboardData(token, requestBody, abortControllerRef.current?.signal);
          if (response) {
              setDashboardData(response.pageResults);
              setTotalCount(response.totalCount);
              setPage(0);
              setSearchLoading(false);
          }
        } catch (error:any) { 
          if (error.status === 401) {
              await handleReauthenticationAndRetry(token, requestBody, fetchDataAndUpdateUI);
            }  else if (error.status === 400) {
                setSearchLoading(false);
                setDashboardData([]);
                setChatData(null);
            } else {
              setSearchLoading(false);
              setDashboardData([]);
              setChatData(null);
            }
        } finally {
          if (abortControllerRef.current?.signal.aborted) {
            setSearchLoading(true);
          } 
        }   
      }
  };

  const fetchDashboardLocationsData = async (newPartnerId: string | undefined, requestBody: any, pageNum: number, rowsPerPage: number) => {
    if (newPartnerId || firstPartnerId) {
        const count = pageNum;
        updateCurrentRequestBody({ ...currentRequestBody, ...requestBody, partnerId: newPartnerId ? newPartnerId : firstPartnerId, skip: count, take: rowsPerPage });
        await fetchDataAndUpdateUIWithLocations(requestBody);
    }
  };

  const fetchDataAndUpdateUIWithLocations = async (requestBody: any) => {
    const storedUser: any = localStorage.getItem('accessToken');
    const token = JSON.parse(storedUser); 
    if (requestBody.partnerId !== '') {
      try {
        const response: any = await GetLocation(token, requestBody.partnerId);
        if (response) {
            setLocationsCheckboxList(response);
        }
      } catch (error:any) {
          console.error('Error fetching locations data:', error.status);
          if (error.status === 401) {
            await handleReauthenticationAndRetry(token, requestBody, fetchDataAndUpdateUIWithLocations);
          }  else if (error.status === 400) {
              setLocationsCheckboxList([]);
          } else {
            setLocationsCheckboxList([]);
          }
      }
    }
  };

  const handleReauthenticationAndRetry = async (token: string, requestBody: any, retryFunction: (requestBody: any) => Promise<void>) => {
    const refreshToken: string | null = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        handleLogout();
        return;
    }
    try {
        const reAuthResponse = await ReAuthenticate(token, refreshToken);
        localStorage.setItem('accessToken', JSON.stringify(reAuthResponse.accessToken));
        localStorage.setItem('refreshToken', JSON.stringify(reAuthResponse.refreshToken));
        await retryFunction(requestBody);
    } catch (error) {
        console.error('Error reauthenticating:', error);
        handleLogout();
        return;
    }
  };

  const handleLoadChatData = async (data: any) => {
    setChatData(null);
    setModalLoading(true);
    const storedUser: any = localStorage.getItem('accessToken');
    const token = JSON.parse(storedUser);
    if(data.sessionId) {
        try {          
            await loadChatData(token, data);
            setModalLoading(false);
        } catch (error: any) {
            console.error('Error fetching data:', error, error.status);
            if (error.status === 401) {
              try {
                  await retryAfterReauthentication(token, data, loadChatData);
              } catch (reAuthError) {
                  handleLogout();
                  return;
              } finally {
                setModalLoading(false);
              }
            } else {
              setModalLoading(false);
            }
        }
    }
  };

  const loadChatData = async (token: string | null, data: any) => {
      const response: any = await GetChatHistory(token, data.sessionId, data.petOwnerId);
      setChatData(response);
  };

  const loadPDFData = async (token: string | null, id: string) => {
    const pdfData: any = await GetPDFData(token, id);
    if (!pdfData || !pdfData.fileName || !pdfData.bits || !pdfData.contentType) {
      throw new Error('Invalid PDF data');
    }
    const pdfBlob = base64ToBlob(pdfData.bits, pdfData.contentType);
    FileSaver.saveAs(pdfBlob, pdfData.fileName);
  };

  const handleLoadPDF = async (id: string) => {
    setPDFDownloading(true);
    const storedUser: any = localStorage.getItem('accessToken');
    const token = JSON.parse(storedUser);
    try {
      await loadPDFData(token, id);
      setPDFDownloading(false);
    } catch (error:any) {
      console.error('Error fetching data:', error);
      setPDFDownloading(false);
      if (error.status === 401) {
        try {
          await retryAfterReauthentication(token, id, loadPDFData);
        } catch (reAuthError) {
          handleLogout();
        }
      }
    }
  };
  
  const base64ToBlob = (base64Data: string, contentType: string) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512); 
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  useEffect(() => {  
    const token = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    const fetchUserDataAndDashboard = async (token:any) => {
      setLoading(true);
      setTodaysSummaryLoading(true);
      setSummaryLoading(true);
      setReferringLoading(true);
      try {
        const userData = await FetchUserData(token);
        setUser(userData);
        const defaultPartnerId = userData.partnerMappings[0].partnerId;
        const defaultPartnerName = userData.partnerMappings[0].partnerName;
        const storedPartnerId: any = localStorage.getItem('partnerId');
        const storedPartnerName: any = localStorage.getItem('partnerName');
        localStorage.setItem('dashboardState', JSON.stringify(parsedDashboardState.partnerId = storedPartnerId));
        localStorage.setItem('reportDataState', JSON.stringify({...parsedReportDashboardState, locations: ['All Locations']}));

        if (storedPartnerId) {
          setSelectedPartnerId(storedPartnerId);
          setSelectedPartnerName(storedPartnerName);
          handlePartnerChange(storedPartnerId, storedPartnerName);
        } else {
          handlePartnerChange(defaultPartnerId, defaultPartnerName);
        }

        updateCurrentRequestBody({...parsedDashboardState, partnerId: storedPartnerId ? storedPartnerId : defaultPartnerId, skip: parsedDashboardState.skip / 10 * 10 });
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Check if the error is due to 401 unauthorized
        //if (error && error.status === 401) {
          try {
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) {
              handleLogout();
              return;
            }
            const reAuthResponse = await ReAuthenticate(token, refreshToken);
            localStorage.setItem('accessToken', JSON.stringify(reAuthResponse.accessToken));
            // Retry fetching user data after successful reauthentication
            await fetchUserDataAndDashboard(reAuthResponse.accessToken);
          } catch (reAuthError) {
            setLoading(false);
            handleLogout();
            return;
          }
      // } else {
          // For other errors, log out the user
          //console.log('User unauthorized. Logging out...');
          //handleLogout();
        //}
      } finally {
        setLoading(false);
      }
    };

    if (token && refreshToken) {
      setLoading(true);
      setLoggedIn(true);
      const tokenParsed = JSON.parse(token); // Parse the stored token
      fetchUserDataAndDashboard(tokenParsed);
    } else {
      handleLogout();
      setLoggedIn(false);
    }
  }, []);

  const loginUser = async (username: string, password: string) => {
      try {
        const response = await AuthenticateUser(username, password);
        const authResponse = await response;
        const loginToken = authResponse.accessToken;
        const refreshToken = authResponse.refreshToken;
        setServerError('');
    
        if (loginToken && refreshToken) {
          localStorage.setItem('accessToken', JSON.stringify(loginToken));
          localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
          return loginToken;
        } else {
          throw new Error('Invalid tokens');
        }
      } catch (error: any) {
        setServerError(`Failed to login: ${error.message}`);
        setLoading(false);
      }
  };
  
  const fetchUserData = async (token: string) => {
    try {
      const userData = await FetchUserData(token);
      return userData;
    } catch (error) {
      throw new Error('Failed to fetch user data');
    }
  };
  
  const handleLogin = async (username: string, password: string) => {
    setLoading(true);
    let token;

    try {
      token = await loginUser(username, password);
    } catch(error) {
      setLoading(false);
    }
    if(token) {
      try { 
        const userData = await fetchUserData(token);
        setError('');
        setUser(userData);
        if(userData.partnerMappings.length > 0) {
          handlePartnerChange(userData.partnerMappings[0].partnerId, userData.partnerMappings[0].partnerName);
          updateCurrentRequestBody({...currentRequestBody, partnerId: userData.partnerMappings[0].partnerId });
          setSelectedPartnerId(userData.partnerMappings[0].partnerId);
          setSelectedPartnerName(userData.partnerMappings[0].partnerName);
          setLoggedIn(true);
          setCurrentPage(0);
          setLoading(false);
          setChatData(null);
          handleLoadCountData(userData.partnerMappings[0].partnerId, 'ThisMonth', fromDate, toDate);
          handleLoadReferringUrlData(userData.partnerMappings[0].partnerId, 'ThisMonth', fromDate, toDate);
          fetchDashboardData(parsedDashboardState.partnerId, {...currentRequestBody, partnerId: userData.partnerMappings[0].partnerId}, parsedDashboardState.skip, rowsPerPage);
        }
      } catch (error:any) {
        setError('An error occurred during login. Please try again later.');
        setLoading(false);
        if (error.status === 401) {
          try {
            const refreshToken: string | null = localStorage.getItem('refreshToken');
            if (!refreshToken) {
              throw new Error('Refresh token not found');
            }
            const reAuthResponse = await ReAuthenticate(token, refreshToken);
            localStorage.setItem('accessToken', JSON.stringify(reAuthResponse.accessToken));
            // Retry fetching user data after successful reauthentication
            await handleLogin(username, password);
          } catch (reAuthError) {
            console.error('Error reauthenticating:', reAuthError);
            handleLogout();
          }
        }
      }
    }
  };

  const handleLogout = async () => {
    const accessToken: any = localStorage.getItem('accessToken');
    const refreshToken: any = localStorage.getItem('refreshToken');
    if (accessToken !== null && refreshToken !== null) {
      try {
          setLoading(true);
          await LogOut(accessToken, refreshToken);
      } catch (error) {
        setLoading(false);
        throw new Error('Failed to logout');
      } finally {
        setLoggedIn(false);
        setUser(null);
        setSelectedPartnerId('');
        setSelectedPartnerName('');
        setFirstPartnerId(''); 
        setCurrentPage(0);
        setTodaysSummary({
            newVisitorsToday: 0,
            repeatVisitorsToday: 0,
            totalVeraChatsToday: 0,
            requestedLiveChatToday: 0
        });
        setSummary({
            newVisitorsInDateRange: 0,
            repeatVisitorsInDateRange: 0,
            totalVeraChatsInDateRange: 0,
            requestedLiveChatInDateRange: 0
        });
        setReferringUrls([]);
        setDashboardData([]);
        setChatData(null);
        setCurrentRequestBody({
          skip: 0,
          take: 10,
          sorts: [{ sort: 'CreatedOn', isAscending: true }],
          partnerId: '',
          customStartDate: fromDate,
          customEndDate: toDate,
          searchText: '',
          isDateFilter: true,
          dateFilter: 'ThisMonth',
          chatTypes: ['All'],
          locations: ['All Locations'],
          isChatType: true,
        });
        setSelectedCheckboxData(['']);
        setChatData(null);
        //setResetEmail('');
        //setResetCode('');
        //window.location.reload();
        setLoading(false);
        localStorage.clear();

      }
    }
  };

  const convertDateToAPIFormat = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00.000Z`;
  };

  const handleLoadCountData = async (partnerId: string | undefined, dateFilter: string, startDate: string, endDate: string) => {
    const convertedFromDate = convertDateToAPIFormat(startDate);
    const convertedToDate = convertDateToAPIFormat(endDate);
    localStorage.setItem('storedSummaryData', JSON.stringify({
      customStartDate: startDate,
      customEndDate: endDate,
      partnerId: partnerId,
      dateFilter: dateFilter})); 
    const requestBody = {
      customStartDate: startDate,
      customEndDate: endDate,
      partnerId: partnerId,
      dateFilter: dateFilter
    }; 
    const token: any = localStorage.getItem('accessToken');
    await loadCountData(token, requestBody);
  };

  const handleLoadReferringUrlData = async (partnerId: string | undefined, dateFilter: string, startDate: string, endDate: string) => {
    const convertedFromDate = convertDateToAPIFormat(startDate);
    const convertedToDate = convertDateToAPIFormat(endDate);
    localStorage.setItem('storedReferringUrlData', JSON.stringify({
      customStartDate: startDate,
      customEndDate: endDate,
      partnerId: partnerId,
      dateFilter: dateFilter})); 
    const requestBody = {
      customStartDate: startDate,
      customEndDate: endDate,
      partnerId: partnerId,
      dateFilter: dateFilter
    }; 
    const token: any = localStorage.getItem('accessToken');
    await loadReferringUrlData(token, requestBody);
  };

  const loadCountData = async (token: string | null, requestBody: any) => {
    try {
      const countData: any = await GetDashboardCounts(token, requestBody); 
      const stats = [
        { label: 'Repeat Visitors', value: countData.repeatVisitorsToday },
        { label: 'Total Vera Chats', value: countData.totalVeraChatsToday },
        { label: 'Total All Chats', value: countData.requestedLiveChatToday } 
      ];
      const statsRange = [
        { label: 'Repeat Visitors', value: countData.repeatVisitorsInDateRange },
        { label: 'Total Vera Chats', value: countData.totalVeraChatsInDateRange },
        { label: 'Total All Chats', value: countData.requestedLiveChatInDateRange }
      ];
      setTodaysSummary(stats);
      setSummary(statsRange);
      setSummaryLoading(false);
      setTodaysSummaryLoading(false);
    } catch (error:any) {
        setTodaysSummary([]);
        setSummary([]);
        setSummaryLoading(false);
        setTodaysSummaryLoading(false);
        if (error.status === 401) {
          try {
            await retryAfterReauthentication(token, requestBody, loadCountData);
          } catch (reAuthError) {
            setSummaryLoading(false);
            setTodaysSummaryLoading(false);
            handleLogout();
            return;
          }
        }
    }
  };

  const loadReferringUrlData = async (token: string | null, requestBody: any) => {
    try {
      const urlRange: any[] = await GetDashboardReferringUrl(token, requestBody); 
      setReferringUrls(urlRange);
      setReferringLoading(false);
    } catch (error:any) {
        setReferringUrls([]);
        setReferringLoading(false);
        if (error.status === 401) {
          try {
            await retryAfterReauthentication(token, requestBody, loadReferringUrlData);
          } catch (reAuthError) {
            setReferringLoading(false);
            handleLogout();
            return;
          }
        }
    }
  };

  const retryAfterReauthentication = async (token: any | null, requestBody: any, fetchDataAndUpdateUI: Function) => {
    const refreshToken: string | null = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        throw new Error('Refresh token not found');
    }
    const reAuthResponse = await ReAuthenticate(token, refreshToken);
    const newToken = reAuthResponse.accessToken;
    await fetchDataAndUpdateUI(newToken, requestBody);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    setPage(newPage);
    updateCurrentRequestBody({...currentRequestBody, skip: newPage * 10, take: rowsPerPage });
    
    fetchDashboardData(selectedPartnerId, {...currentRequestBody, skip: newPage * 10}, newPage * 10, rowsPerPage);
  };
  
  const handleUsernameChange = (newUsername: string) => {
    setUsername(newUsername);
    validateInputs(newUsername, password);
  };

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword);
    validateInputs(username, newPassword);
  };

  const validateInputs = (newUsername: string, newPassword: string) => {
    if (!newUsername && !newPassword) {
      setError('Please enter both username and password.');
    } else if (!newUsername) {
      setError('Please enter your username.');
    } else if (!newPassword) {
      setError('Please enter your password.');
    } else {
      setError('');
    }
  };

  const handleGridFilterSubmit = async (filterData: any) => {
    const dateFilter = determineDateType(filterData.selectedRadioValue);
    let chatType;
    if (filterData.selectedChatTypeValues != undefined) {
      chatType = determineChatType(filterData.selectedChatTypeValues);
    } else {
      chatType = determineChatType(filterData.prevSelectedChatTypeValues);
    }
    let locations;
    if (filterData.selectedLocationsValues != undefined) {
      locations = filterData.selectedLocationsValues;
    } else {
      locations = filterData.prevSelectedLocationsValues;
    }
    let startD = filterData.fromDate ? new Date(filterData.fromDate) : new Date(currentRequestBody.customStartDate);
    let endD = filterData.toDate ? new Date(filterData.toDate) : new Date(currentRequestBody.customEndDate);
    startD.setDate(startD.getDate() + 1);
    endD.setDate(endD.getDate() + 1);

    updateCurrentRequestBody({
      ...currentRequestBody, 
      customStartDate: convertDateToAPIFormat(startD.toString()),
      customEndDate: convertDateToAPIFormat(endD.toString()),
      isDateFilter: true,
      dateFilter: dateFilter,
      chatTypes: chatType,
      locations: locations,
      partnerId: selectedPartnerId, 
      skip: 0
    });

    fetchDashboardData(
      selectedPartnerId, 
      {...currentRequestBody, 
      customStartDate: convertDateToAPIFormat(startD.toString()),
      customEndDate: convertDateToAPIFormat(endD.toString()),
      isDateFilter: true,
      dateFilter: dateFilter,
      chatTypes: chatType,
      locations: locations,
      skip: 0
    }, page, rowsPerPage);
    setCurrentPage(0);
  };

  enum DateFilter {
    Custom = 'Custom',
    Today = 'Today',
    ThisWeek = 'ThisWeek',
    LastWeek = 'LastWeek',
    ThisMonth = 'ThisMonth',
    LastMonth = 'LastMonth',
    ThisYear = 'ThisYear',
    All = 'All'
  }

  const determineDateType = (selectedDateFilter: string): DateFilter => {
    let dateType: DateFilter = DateFilter.ThisMonth;

    switch (selectedDateFilter) {
      case 'Today':
        dateType = DateFilter.Today;
        break;
      case 'This Week':
        dateType = DateFilter.ThisWeek;
        break;
      case 'Month-to-Date':
        dateType = DateFilter.ThisMonth;
        break;
      case 'Last Month':
        dateType = DateFilter.LastMonth;
        break;
      case 'Year-to-Date':
        dateType = DateFilter.ThisYear;
        break;
      case 'All Dates':
        dateType = DateFilter.All;
        break;
      case 'Custom':
        dateType = DateFilter.Custom;
        break;
      case 'Custom date range':
        dateType = DateFilter.Custom;
        break;
      case undefined:
        dateType = DateFilter.ThisMonth;
        break;
    }
    return dateType;
  };

  const determineChatType = (selectedChatTypeValues: string[]) => {
      const isChatTypeSelected = selectedChatTypeValues.includes('Chat Type');
      const filteredChatTypes = selectedChatTypeValues.filter(value => value.trim() !== '');
      if (isChatTypeSelected && filteredChatTypes.length === 1) {
        return ['All'];
      } 
      const result = filteredChatTypes.filter(value => value !== 'Chat Type'); 
      if (!isChatTypeSelected) {
        setSelectedCheckboxData(result);
      }
      return result;
  };

  const handleSearchRequest = async (searchTerm: any) => {
    updateCurrentRequestBody({...currentRequestBody, searchText: searchTerm, skip: 0});
    fetchDashboardData(selectedPartnerId, {...currentRequestBody, searchText: searchTerm, skip: 0}, 0, rowsPerPage);
    setCurrentPage(0);
  };

  const handleSortRequest = async (sortTerm: any) => {
    localStorage.setItem('dashboardState', JSON.stringify(parsedDashboardState.sorts[0].isAscending = parsedDashboardState.sorts[0].isAscending === true ?  false : true));
    updateCurrentRequestBody({...currentRequestBody, partnerId: selectedPartnerId, sorts: [{ sort: sortTerm.field, isAscending: parsedDashboardState.sorts[0].isAscending }], skip: 0 });
    await fetchDashboardData(selectedPartnerId, {...currentRequestBody, sorts: [{ sort: sortTerm.field, isAscending: parsedDashboardState.sorts[0].isAscending }], skip: 0 }, page, rowsPerPage);
    setCurrentPage(0);
  };

  const fetchReportsData = async (token: string | null, requestBody: any, setServerError: any) => {
    const CSVData: any = await GetDashboardReports(token, requestBody, setServerError);
    if (!CSVData || !CSVData.fileName || !CSVData.bits || !CSVData.contentType) {
      throw new Error('Invalid CSV data');
    }
    const pdfBlob = base64ToBlob(CSVData.bits, CSVData.contentType);
    FileSaver.saveAs(pdfBlob, CSVData.fileName);
  };

  const downloadReferringUrlCSV = async (filterData: any) => {
    const csvHeader = 'URL,COUNT';
    const csvRows = referringUrls.map(({ url, count }) => `${url},${count}`);
    const csvString = [csvHeader, ...csvRows].join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    let ReferringLinkName = '';
    if(filterData.selectedRadioValue === 'Custom date range'){
      ReferringLinkName = `${selectedPartnerName}_Referring_urls_From_${filterData.fromD}_To_${filterData.toD}.csv`;
    } else {
      ReferringLinkName = `${selectedPartnerName}_Referring_urls_${filterData.selectedRadioValue}.csv`
    }
    downloadLink.download = ReferringLinkName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  };

  const retryAfterReauthenticationForReports = async (token: any | null, requestBody: any, setServerError: Function) => {
    const refreshToken: string | null = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('Refresh token not found');
    }
    const reAuthResponse = await ReAuthenticate(token, refreshToken);
    const newToken = reAuthResponse.accessToken;
    await fetchReportsData(newToken, requestBody, setServerError);
  };
  
  const handleReportFilterSubmit = async (filterData: any) => {
    setModalLoading(true);
    setServerError('');
    const dateType = determineDateType(filterData.date);
    const chatType = determineChatType(filterData.selectedReportChatTypeValues);
    const locations = filterData.selectedReportLocationValues;
    let startD: any = "";
    let endD: any = "";
    if (dateType === 'Custom') {
      startD = filterData.fromDateReport ? new Date(filterData.fromDateReport) : new Date('');
      endD = filterData.toDateReport ? new Date(filterData.toDateReport) : new Date('');
      convertDateToAPIFormat(startD.setDate(startD.getDate()).toString());
      convertDateToAPIFormat(endD.setDate(endD.getDate()).toString());
    } else {
      startD = new Date('2024-04-15T17:49:22.525Z');
      endD = new Date('2024-04-15T17:49:22.525Z');
    }
  
    const requestBody = {
      dateFilter: dateType,
      customStartDate: startD,
      customEndDate: endD,
      reportName: "DashboardReport",
      email: "",
      when: "Now",
      includeUpdates: true,
      includeAnonymized: true,
      sourceIds: [
        selectedPartnerId
      ],
      promotionIds: [
        "string"
      ],
      set: "Normal",
      segmentId: 0,
      practiceLocationId: 0,
      includePosts: true,
      excludeNoNetCharge: true,
      activeFilter: true,
      autoRenewFilter: true,
      archiveFilter: true,
      includeIncompleteStatuses: true,
      isMarkSubmitted: true,
      welcomeKitFilter: true,
      lostPetFilter: true,
      foundPetFilter: true,
      recoveredPetFilter: true,
      chatTypes: chatType,
      locations: locations
    };

    localStorage.setItem('reportDataState', JSON.stringify(requestBody));

    const storedUser: any = localStorage.getItem('accessToken');
    const token = JSON.parse(storedUser);
    try {
      await fetchReportsData(token, requestBody, setServerError);
      setModalLoading(false);
    } catch (error: any) {
      if (error.message === 'Failed to fetch') {
        try {
          await retryAfterReauthenticationForReports(token, requestBody, setServerError);
        } catch (reAuthError:any) {
          if(reAuthError.message === 'Failed to fetch') {
            handleLogout();
            return;
          } else {
            setServerError(`Error fetching CSV data.`);
          }
        }
      } else {
        setServerError(`Error fetching CSV data.`);
      }
    } finally {
      setModalLoading(false);
    }
  };

  const handleDateFilterSummarySubmit = async (filterData: any) => {
    let fromDate: string;
    let toDate: string;

    setSummaryLoading(true);

    switch (filterData.selectedRadioValue) {
      case 'All Dates':
          const currentAllDate = new Date();
          const oneAllYearAgo = new Date(currentAllDate);
          oneAllYearAgo.setFullYear(oneAllYearAgo.getFullYear() - 10);
          fromDate = oneAllYearAgo.toISOString();
          toDate = currentAllDate.toISOString();
          handleLoadCountData(selectedPartnerId, 'All', fromDate, toDate);
          break;
      case 'Today':
          const currentDayStart = new Date();
          currentDayStart.setDate(currentDayStart.getDate() - currentDayStart.getDay());
          const currentDayEnd = new Date();
          currentDayEnd.setDate(currentDayEnd.getDate() - currentDayEnd.getDay() + 6);
          fromDate = currentDayStart.toISOString();
          toDate = currentDayEnd.toISOString();
          handleLoadCountData(selectedPartnerId, 'Today', fromDate, toDate);
          break;
      case 'This Week':
          const currentWeekStart = new Date();
          currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
          const currentWeekEnd = new Date();
          currentWeekEnd.setDate(currentWeekEnd.getDate() - currentWeekEnd.getDay() + 6);
          fromDate = currentWeekStart.toISOString();
          toDate = currentWeekEnd.toISOString();
          handleLoadCountData(selectedPartnerId, 'ThisWeek', fromDate, toDate);
          break;
      case 'Month-to-Date':
          const currentMonth = new Date();
          const lastMonthStart = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, currentMonth.getDate());
          fromDate = lastMonthStart.toISOString();
          toDate = currentMonth.toISOString();
          handleLoadCountData(selectedPartnerId, 'ThisMonth', fromDate, toDate);
          break; 
      case 'Last Month':
          const currentLastMonth = new Date();
          const lastLastMonthStart = new Date(currentLastMonth.getFullYear(), currentLastMonth.getMonth() - 1, currentLastMonth.getDate());
          fromDate = lastLastMonthStart.toISOString();
          toDate = currentLastMonth.toISOString();
          handleLoadCountData(selectedPartnerId, 'LastMonth', fromDate, toDate);
          break;
      case 'Year-to-Date':
          const currentDate = new Date();
          const oneYearAgo = new Date(currentDate);
          oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
          fromDate = oneYearAgo.toISOString();
          toDate = currentDate.toISOString();
          handleLoadCountData(selectedPartnerId, 'ThisYear', fromDate, toDate);
          break;
       default:
          const fromDateWithoutTime = new Date(filterData.fromDate);
          fromDateWithoutTime.setDate(fromDateWithoutTime.getDate());
          fromDate = fromDateWithoutTime.toISOString();
          const toDateWithoutTime = new Date(filterData.toDate);
          toDateWithoutTime.setDate(toDateWithoutTime.getDate());
          toDate = toDateWithoutTime.toISOString();
          handleLoadCountData(selectedPartnerId, 'Custom', fromDate, toDate);
          break;
    }
  };

  const handleDateFilterReferringURLSubmit = async (filterData: any) => { 
    let fromDate: string;
    let toDate: string;

    setReferringLoading(true);

    switch (filterData.selectedRadioValue) {
      case 'All Dates':
          const currentAllDate = new Date();
          const oneAllYearAgo = new Date(currentAllDate);
          oneAllYearAgo.setFullYear(oneAllYearAgo.getFullYear() - 10);
          fromDate = oneAllYearAgo.toISOString();
          toDate = currentAllDate.toISOString();
          handleLoadReferringUrlData(selectedPartnerId, 'All', fromDate, toDate);
          break;
      case 'Today':
          const currentDayStart = new Date();
          currentDayStart.setDate(currentDayStart.getDate() - currentDayStart.getDay());
          const currentDayEnd = new Date();
          currentDayEnd.setDate(currentDayEnd.getDate() - currentDayEnd.getDay() + 6);
          fromDate = currentDayStart.toISOString();
          toDate = currentDayEnd.toISOString();
          handleLoadReferringUrlData(selectedPartnerId, 'Today', fromDate, toDate);
          break;
      case 'This Week':
          const currentWeekStart = new Date();
          currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
          const currentWeekEnd = new Date();
          currentWeekEnd.setDate(currentWeekEnd.getDate() - currentWeekEnd.getDay() + 6);
          fromDate = currentWeekStart.toISOString();
          toDate = currentWeekEnd.toISOString();
          handleLoadReferringUrlData(selectedPartnerId, 'ThisWeek', fromDate, toDate);
          break;
      case 'Last Month':
            const currentLastMonth = new Date();
            const lastLastMonthStart = new Date(currentLastMonth.getFullYear(), currentLastMonth.getMonth() - 1, currentLastMonth.getDate());
            fromDate = lastLastMonthStart.toISOString();
            toDate = currentLastMonth.toISOString();
            handleLoadCountData(selectedPartnerId, 'LastMonth', fromDate, toDate);
            break;
      case 'Month-to-Date':
          const currentMonth = new Date();
          const lastMonthStart = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, currentMonth.getDate());
          fromDate = lastMonthStart.toISOString();
          toDate = currentMonth.toISOString();
          handleLoadReferringUrlData(selectedPartnerId, 'ThisMonth', fromDate, toDate);
          break;
      case 'Year-to-Date':
          const currentDate = new Date();
          const oneYearAgo = new Date(currentDate);
          oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
          fromDate = oneYearAgo.toISOString();
          toDate = currentDate.toISOString();
          handleLoadReferringUrlData(selectedPartnerId, 'ThisYear', fromDate, toDate);
          break;
       default:
          const fromDateWithoutTime = new Date(filterData.fromDate);
          fromDateWithoutTime.setDate(fromDateWithoutTime.getDate());
          fromDate = fromDateWithoutTime.toISOString();
          const toDateWithoutTime = new Date(filterData.toDate);
          toDateWithoutTime.setDate(toDateWithoutTime.getDate());
          toDate = toDateWithoutTime.toISOString();
          handleLoadReferringUrlData(selectedPartnerId, 'Custom', fromDate, toDate);
          break;
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setServerError(null);
  };


  const cancelResetPassword = () => {
    setServerError('');
    setResetEmail('');
    setResetCode('');
  };

  const openPasswordReset = () => {
    setServerError('');
  }

  const handleSendEmail = async (email: string): Promise< {message: string | null} > => {
    setResetEmail(email);
    setLoading(true);
    try {
      const sendEmailResponse = await ForgotPassword(email);
        setResetEmailSuccess(true);
        setServerError('');
        setLoading(false);
        return sendEmailResponse;
    } catch (error:any) {
        console.error('Error sending forgot password request:', error);
        setServerError(error);
        setResetEmailSuccess(false);
        setLoading(false);
        return error.message;
    }
  };

  const handleVerifyCode = async (code: string): Promise< {message: string | null | undefined} > => {
    setLoading(true);
    setResetCode(code);
    if(code !== '' && resetEmail !== '') {
      try {
        const sendCodeResponse = await SendResetCode(resetEmail, code);
        setResetCodeSuccess(true);
        setLoading(false);
        setServerError('');
        return sendCodeResponse;
      } catch (error:any) {
          console.error('Error sending forgot password request:', error);
          setLoading(false);
          setResetCodeSuccess(false);
          setServerError(error);
          return error.message;
      }
    } else {
      setLoading(false);
      setResetCodeSuccess(false);
      setServerError('Please enter your email address first to reset your password.');
      return {message: undefined};
    }
  };

  interface ResetPasswordHandler {
    (password: string): Promise<{message: string | null | undefined, code: number | null | undefined, errors: string[] | null | undefined }>;
  }
  const handleResetPassword: ResetPasswordHandler = async (newPassword: string) => {
    setLoading(true);
    if(resetCode !== '' && resetEmail !== '' && newPassword !== '') {
      try {
        const resetResponse = await ResetPassword(resetEmail, resetCode, newPassword);
        if (resetResponse.accessToken && resetResponse.refreshToken) {
          localStorage.setItem('accessToken', JSON.stringify(resetResponse.accessToken));
          localStorage.setItem('refreshToken', JSON.stringify(resetResponse.refreshToken));
          setLoggedIn(true);
          try {
            const userData = await fetchUserData(resetResponse.accessToken);
            setError('');
            setUser(userData);
            handlePartnerChange(userData.partnerMappings[0].partnerId, userData.partnerMappings[0].partnerName);
            updateCurrentRequestBody({...currentRequestBody, partnerId: userData.partnerMappings[0].partnerId });
            setSelectedPartnerId(userData.partnerMappings[0].partnerId);
            setSelectedPartnerName(userData.partnerMappings[0].partnerName);
            setCurrentPage(0);
            setChatData(null);
            handleLoadCountData(userData.partnerMappings[0].partnerId, 'ThisMonth', fromDate, toDate);
            handleLoadReferringUrlData(userData.partnerMappings[0].partnerId, 'ThisMonth', fromDate, toDate);
            fetchDashboardData(userData.partnerMappings[0].partnerId, {...currentRequestBody, partnerId: userData.partnerMappings[0].partnerId, skip: 0 }, 0, rowsPerPage);
            setLoading(false);
            return resetResponse;
          } catch (error:any) {
            setError('An error occurred during login. Please try again later.');
            setLoading(false);
            return error.message;
          }
        } else {
            setServerError('Error: Access token or refresh token missing in reset response');
            setLoading(false);
        } 
      } catch (error:any) {
        setServerError(error);
        setLoading(false);
        return error;
      }
    } else {
        setLoading(false);
        setResetCodeSuccess(false);
        setServerError('Please enter your email address first to reset your password.');
        return {message: undefined};
    }
  };

  let formattedPartnerName = selectedPartnerName.replace(/ /g, '_').toLowerCase();

  return (
    <Router>
      <div className={classes.appContainer}>
        <PageHeader
          onLogout={handleLogout}
          isLoggedIn={isLoggedIn}
          onOpenReportDialog={handleOpenDialog}
          userData={user}
          onPartnerChange={handlePartnerChange}
        />
        {loading ? (
          <Paper elevation={0} className="pageLoaderContainer">
            <Typography className="pageLoaderImage">
              <span className="loaderCircleSmall"></span>
              <span className="loaderCircleBig"></span>
            </Typography>
            <Typography className="pageLoaderText">PROCESSING...</Typography>
          </Paper>
        ) : (
          <Suspense
            fallback={
              <Paper elevation={0} className="pageLoaderContainer">
                <Typography className="pageLoaderImage">
                  <span className="loaderCircleSmall"></span>
                  <span className="loaderCircleBig"></span>
                </Typography>
                <Typography className="pageLoaderText">PROCESSING...</Typography>
              </Paper>
            }
          >
            <Routes>
              {isLoggedIn || localStorage.getItem('accessToken') !== null ? (
                <>
                  <Route
                    path="/login"
                    element={<Navigate replace to={`/${formattedPartnerName}`} />}
                  />
                  <Route
                    path={`/${formattedPartnerName}`}
                    element={
                      <>
                        <div className="statsContainer">
                          <StatsCard
                            cardType="circle"
                            visibility="visible"
                            storedData={storedSummaryState}
                            isSummaryLoading={summaryLoading}
                            title="Summary"
                            stats={summary}
                            onSubmitDateFilter={handleDateFilterSummarySubmit}
                          />
                          <StatsCard
                            cardType="bar"
                            visibility="visible"
                            storedData={storedReferringState}
                            isReferringLoading={referringUrlLoading}
                            title="Referring Url"
                            stats={referringUrls.slice(0, 10)}
                            onSubmitDateFilter={handleDateFilterReferringURLSubmit}
                            downloadCSVClick={downloadReferringUrlCSV}
                          />
                        </div>
                        <GridComponent
                          data={dashboardData}
                          locationsData={locationsCheckboxList}
                          chatData={chatData}
                          isDialogOpen={dialogOpen}
                          onCloseDialog={handleCloseDialog}
                          onSubmitGridFilter={handleGridFilterSubmit}
                          onSubmitReportFilter={handleReportFilterSubmit}
                          onSearchRequest={handleSearchRequest}
                          onSort={handleSortRequest}
                          dataLoading={loading}
                          isLoading={searchLoading}
                          isModalLoading={modalLoading}
                          page={page}
                          currentPage={currentPage}
                          rowsPerPage={rowsPerPage}
                          totalCount={totalCount}
                          handleChangePage={handleChangePage}
                          handlePDFClick={handleLoadPDF}
                          isPDFDownloading={pdfDownloading}
                          handleGetChatData={handleLoadChatData}
                          selectedCheckboxVal={selectedCheckboxData}
                          serverError={serverError}
                        />
                      </>
                    }
                  />
                  <Route
                    path="*"
                    element={<Navigate replace to={`/${formattedPartnerName}`} />}
                  />
                </>
              ) : (
                <>
                  <Route
                    path="/login"
                    element={
                      <Login
                        onLogin={handleLogin}
                        error={error}
                        serverError={serverError}
                        onUsernameChange={handleUsernameChange}
                        onPasswordChange={handlePasswordChange}
                        handleForgotPasswordNavigate={openPasswordReset}
                      />
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <SendEmailPage
                        handleSendEmail={handleSendEmail}
                        handleCancelReset={cancelResetPassword}
                        serverError={serverError}
                        handleSuccess={resetEmailSuccess}
                      />
                    }
                  />
                  <Route
                    path="/verify-code"
                    element={
                      <VerifyCodePage
                        handleVerifyCode={handleVerifyCode}
                        handleCancelReset={cancelResetPassword}
                        serverError={serverError}
                        handleSuccess={resetCodeSuccess}
                      />
                    }
                  />
                  <Route
                    path="/reset-password"
                    element={
                      <ConfirmNewPasswordPage
                        handleResetPassword={handleResetPassword}
                        handleCancelReset={cancelResetPassword}
                        serverError={serverError}
                      />
                    }
                  />
                  <Route path="*" element={<Navigate replace to="/login" />} />
                </>
              )}
            </Routes>
          </Suspense>
        )}
        <PageFooter />
      </div>
    </Router>
  );
  
};
export default Dashboard;