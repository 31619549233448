import { AddUserRequest } from '../types/types';
import environment from '../environment';

 const apiUrl = process.env.REACT_APP_API_BASE_URL;
 const reportUrl = process.env.REACT_APP_REPORT_API_BASE_URL;

//const apiUrl = "https://api-qatest.ask.vet/api/DashboardUser";
//const reportUrl = "https://rpt-qatest.ask.vet/api/DashboardReport";

const handleResponse = async (response: any) => {
    if (!response.ok) {
        throw response;
    }
    return response.json();
};

const handleErrorResponse = async (response: any) => {
    if (!response.ok) {
        let errorData;
        try {
            errorData = await response.json();
        } catch (error) {
            throw new Error('Failed to parse error response');
        }
        const { message, code, errors } = errorData;
        throw { message, code, errors };
    }
    return response.json();
};

const handleAuthenticatedRequest = async (url: string, token: string, requestOptions: RequestInit) => {
    if (token && token.startsWith('"') && token.endsWith('"')) {
        token = token.slice(1, -1);
    }
    const authorizationHeader = `Bearer ${token}`;
    const response = await fetch(url, {
        ...requestOptions,
        headers: {
            ...requestOptions.headers,
            Authorization: authorizationHeader,
        },
    });
    return handleResponse(response);
};

const handleGetRequest = async (url: string, token: string) => {
    return handleAuthenticatedRequest(url, token, { method: 'GET' });
};

const handlePostRequest = async (url: string, token: string, body: any, options: { signal?: AbortSignal } = {}) => {
    return handleAuthenticatedRequest(url, token, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        ...options
    });
};

const handlePutRequest = async (url: string, token: string, body: any) => {
    return handleAuthenticatedRequest(url, token, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
};

const reAuthenticate = async () => {
  const storedUser: any | null = localStorage.getItem('accessToken');
  const refrToken: any | null = localStorage.getItem('refreshToken');
  const token = JSON.parse(storedUser);
  const refreshToken = JSON.parse(refrToken);

  if (token && refreshToken) {
      try {
          const reAuthResponse = await ReAuthenticate(token, refreshToken);
          localStorage.setItem('accessToken', JSON.stringify(reAuthResponse.accessToken));
          localStorage.setItem('refreshToken', JSON.stringify(reAuthResponse.refreshToken));
      } catch (error:any) {
          await LogOut(token, refreshToken);
          throw new Error(error);
      }
  } else {
      throw new Error('Refresh token not found');
  }
};

const AuthenticateUser = async (username: string, password: string) => {
    try {
        const payload = {
            email: username,
            password: password,
            tryAllAuthenticationProviders: true,
            externalUserId: 'string',
            fullName: 'string'
        };
        const response = await fetch(`${apiUrl}/Auth/Authenticate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData.message || 'Failed to login';
            throw new Error(errorMessage);
        }

        return handleResponse(response);
    } catch (error) {
        throw error;
    }
};

const FetchUserData = async (token: string) => {
    try {
        return handleGetRequest(`${apiUrl}/GetDashboardUser`, token);
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
};

const FetchDashboardUsers = async (token: any | null, partnerId: string | null) => {
    try {
        const payload = {
            skip: 0,
            take: 1000000000,
            sorts: [{ sort: "createdOn", isAscending: true }],
            userStatuses: ['Active', 'Archived'],
            searchText: "",
            partnerId: partnerId
        };
        return handlePostRequest(`${apiUrl}/GetAllDashboardUsers`, token, payload);
    } catch (error) {
        console.error('Error fetching dashboard users:', error);
        if(error == "TypeError: Failed to fetch"){
            throw {status: 401};
        } else {
            throw error;
        }
    }
};

const UpdateDashboardUser = async (token: any | null, userData: AddUserRequest) => {
  try {
      const url = `${apiUrl}/UpdateDashboardUser`;
      const response = await handlePostRequest(url, token, userData);
      return response;
  } catch (error) {
      console.error('Error updating dashboard user:', error);
      if(error == "TypeError: Failed to fetch"){
        throw {status: 401};
      } else {
        throw error;
      }
  }
};

const CreateDashboardUser = async (token: any | null, userData: AddUserRequest, setServerError: (error: any | null) => void) => {
    let response;
    try {
      const url = `${apiUrl}/CreateDashboardUser`;
      response = await handlePostRequest(url, token, userData); 
      return response;
    } catch (error:any) {
        if(error == "TypeError: Failed to fetch"){
            throw {status: 401};
        } else {
            setServerError(error);
            throw error;
        }
    }
};

interface ReAuthenticateResponse {
  message: string;
  code: number;
  errors: string[];
  accessToken: string;
  refreshToken: string;
}

const ReAuthenticate = async (token: string, refrToken: string): Promise<ReAuthenticateResponse> => {
  if (token && token.startsWith('"') && token.endsWith('"')) {
    token = token.slice(1, -1);
  }

  if (refrToken && refrToken.startsWith('"') && refrToken.endsWith('"')) {
    refrToken = refrToken.slice(1, -1);
  }
  const authorizationHeader = `Bearer ${token}`;
  const url = `${apiUrl}/Auth/Refresh`;
  try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: authorizationHeader
          },
          body: JSON.stringify({
              accessToken: token,
              refreshToken: refrToken,
              canAuthenticateViaKongShopify: true
          }),
      });
      
      if (!response.ok) {
          throw new Error('Failed to re-authenticate');
      }

      const responseData = await response.json();
      const { accessToken, refreshToken } = responseData;

      localStorage.setItem('accessToken', JSON.stringify(accessToken));
      localStorage.setItem('refreshToken', JSON.stringify(refreshToken));

      return responseData;

  } catch (error) {
    console.error('Error re-authenticating:', error);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    throw new Error('Error re-authenticating');
  }
};

const LogOut = async (token: string, refreshToken: string): Promise<{ message: string, code: number, errors: string[] }> => {
    if (token && token.startsWith('"') && token.endsWith('"')) {
        token = token.slice(1, -1);
    }
    if (refreshToken && refreshToken.startsWith('"') && refreshToken.endsWith('"')) {
        refreshToken = refreshToken.slice(1, -1);
    }
  
    const authorizationHeader = `Bearer ${token}`;
    const url = `${apiUrl}/Auth/LogOut`;
  
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authorizationHeader
            },
            body: JSON.stringify({
                accessToken: token,
                refreshToken: refreshToken,
                canAuthenticateViaKongShopify: true
            }),
        });
        
        if (!response.ok) {
            const errorResponse = await response.json();
            console.error('Failed to log out, server returned:', errorResponse);
            throw new Error(`Logout failed: ${response.status} ${response.statusText}`);
        }
  
        localStorage.clear();
    
        return await response.json();
    } catch (error) {
        console.error('Error logging out:', error);
        throw error;
    }
};


const GetDashboardCounts = async (token: any | null, requestBody: any) => {
  try {
      const url = `${apiUrl}/GetDashboardCounts`;
      const response = await handlePostRequest(url, token, requestBody);
      return response;
  } catch (error) {
    if(error == "TypeError: Failed to fetch"){
        throw {status: 401};
    } else {
        throw error;
    }
  }
};

const GetDashboardReferringUrl = async (token: any | null, requestBody: any) => {
    try {
        const url = `${apiUrl}/GetReferringUrl`;
        const response = await handlePostRequest(url, token, requestBody);
        return response;
    } catch (error) {
      if(error == "TypeError: Failed to fetch"){
          throw {status: 401};
      } else {
          throw error;
      }
    }
};

const GetLocation = async (token: any | null, partnerId: string) => {
    try {
        const url = `${apiUrl}/GetLocation?partnerId=${partnerId}`;
        const response = await handleGetRequest(url, token);
        return response;
    } catch (error) {
      if(error == "TypeError: Failed to fetch"){
          throw {status: 401};
      } else {
          throw error;
      }
    }
};

const GetDashboardData = async (token: any | null, requestBody: any, signal: AbortSignal) => {
  try {
      const url = `${apiUrl}/GetPartnerChatHistories`;
      const response = await handlePostRequest(url, token, requestBody, {signal});
      
      return response;
  } catch (error:any) {
    if (error.name === 'AbortError') {
        console.log('Aborted');
        return;
    }
    if(error == "TypeError: Failed to fetch"){
        throw {status: 401};
    } else {
        throw error;
    }
  }
};

const GetChatHistory = async (token: any | null, sessionId: string, petOwnerId: string) => {
  try {
      const url = `${apiUrl}/GetChatHistory?sessionId=${sessionId}&petOwnerId=${petOwnerId}`;
      const response = await handleGetRequest(url, token);
      
      return response;
  } catch (error) {
    if(error == "TypeError: Failed to fetch"){
        throw {status: 401};
    } else {
        throw error;
    }
  }
};

const GetPDFData = async (token: any | null, id: string) => {
  try {
      const url = `${apiUrl}/GetSessionSummaryPdf?sessionId=${id}&isDetail=true`;
      const response = await handleGetRequest(url, token);
      
      return response;
  } catch (error) {
      throw error;
  }
};

const GetDashboardReports = async (token: any | null, requestBody: any, setServerError: (error: string | null) => void) => {
  try {
      const url = `${reportUrl}/GetReport?reportId=DashboardChatReport`;
      const response = await handlePostRequest(url, token, requestBody);
      
      return response;
  } catch (error:any) {
      setServerError(error.message || 'Unknown server error occurred');
      throw error;
  }
};

const ForgotPassword = async (email: string) => {
    try {
        const payload = {
            email: email,
            mobileNumber: '',
            emailType: 'ForgotPassword',
            identifierType: 'Email',
            source: 'Unknown',
            userAllAuthProviders: true
        };
        const response = await fetch(`${apiUrl}/Auth/ForgotPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData.message || 'Failed to send reset password email'; 
            throw new Error(errorMessage);
        }

        return handleErrorResponse(response);
    } catch (error:any) {
        console.error('Error sending forgot password request:', error.message);
        throw error.message;
    }
};

const SendResetCode = async (email: string, code: string) => {
    try {
        const payload = {
            identifier: email,
            code: code,
            isPeek: true
        };
        const response = await fetch(`${apiUrl}/Auth/VerifyCode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData.message || 'Failed to send reset password code';
            throw new Error(errorMessage);
        }

        return handleErrorResponse(response);
    } catch (error:any) {
        console.error('Error sending Send Reset Code request:', error.message);
        throw error.message;
    }
};

const ResetPassword = async (email: string, code: string, password: string) => {
    try {
        const payload = {
            email: email,
            mobileNumber: '',
            code: code,
            password: password,
            tryAllAuthProviders: true
        };
        const response = await fetch(`${apiUrl}/Auth/ResetPassword`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData.message || 'Failed to reset password';
            throw new Error(errorMessage);
        }

        return handleErrorResponse(response);
    } catch (error: any) {
        console.error('Error resetting password:', error.message);
        throw error.message;
    }
};

const SetTimeZone = async (token: any | null, userId: string, timeZone: string) => {
    try {
        const url = `${apiUrl}/UpdateDashboardUserTimeZone`;
        const payload = {
            userId: userId,
            timeZone: timeZone
        };
        const response = await handlePutRequest(url, token, payload);
        return response;
    } catch (error) {
        if(error == "TypeError: Failed to fetch"){
          throw {status: 401};
        } else {
          throw error;
        }
    } finally {
        window.location.reload();
    }
};

export { AuthenticateUser, FetchUserData, FetchDashboardUsers, UpdateDashboardUser, CreateDashboardUser, ReAuthenticate, LogOut, GetDashboardCounts, GetDashboardReferringUrl, GetLocation, GetDashboardData, GetChatHistory, GetPDFData, GetDashboardReports, ForgotPassword, SendResetCode, ResetPassword, SetTimeZone };