import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, TextField, Grid, MenuItem, Switch, FormControlLabel, IconButton, Box } from '@material-ui/core';
import { User, AddUserRequest, EditUserRequest } from '../types/types';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    textField: {
        marginBottom: theme.spacing(2),
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                },
            },
        },
        '& .MuiSelect-select.MuiSelect-select': {
            color: 'black',
        },
    },
    gridControlsContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        marginTop: '40px',
        marginBottom: '40px',
        fontSize: '24px',
        fontWeight: 300,
    },
    editHeader: {
        fontSize: '24px',
        fontWeight: 300,
        display: 'flex',
        color: '#231F20',
    }
}));

interface EditUserFormProps {
    user: User;
    onSave: (updatedUser: EditUserRequest, isAdmin: boolean) => void;
    onCancel: () => void;
    statusOptions: { value: string; label: string }[];
    onRefresh: () => void;
}

const EditUserForm: React.FC<EditUserFormProps> = ({
    user,
    onSave,
    onCancel,
    statusOptions,
    onRefresh
}) => {
    const classes = useStyles();

  
  const initialEditedUserState: EditUserRequest = {
    userStatus: user.userStatus,
    password: user.password,
    firstName: user.firstName,
    lastName: user.lastName,
    mobileNumber: user.mobileNumber,
    email: user.email,
    avatarUrl: user.avatarUrl,
    agentDisplayName: user.fullName,
    agentTitle: user.agentTitle,
    selectedPartnerIds: {},
    id: user.id,
  };

  const [editedUser, setEditedUser] = useState<EditUserRequest>(initialEditedUserState)

    useEffect(() => {
        setEditedUser(initialEditedUserState);
    }, [user]);

    const [isAdmin, setIsAdmin] = useState<boolean>(user.partnerMappings[0].isPartnerAdmin);
    
    const [errors, setErrors] = useState<{ [key in keyof AddUserRequest]: string }>({
      userStatus: '',
      email: '',
      password: '',
      mobileNumber: '',
      selectedPartnerIds: '',
    });

    const [serverError, setServerError] = useState<string>('');

    const formatPhoneNumber = (input:string): string => {
        const digits = input.replace(/\D/g, "");
        const match = digits.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/);
        if (match) {
            const [, areaCode, middle, last] = match;
            return `${areaCode ? `(${areaCode}` : ''}${middle ? `) ${middle}` : ''}${last ? `-${last}` : ''}`;
        }
        return input;
    };

    const handleInputChange = (field: keyof AddUserRequest, value: string | boolean | any) => {
        const formattedValue = field === 'mobileNumber' ? formatPhoneNumber(value) : value;

        setEditedUser(prevState => ({
            ...prevState,
            [field]: formattedValue,
        }));
        setErrors({
            ...errors,
            [field]: '',
        });
    };

    const validateFields = () => {
        let isValid = true;
        const updatedErrors: { [key in keyof AddUserRequest]: string } = {
            userStatus: '',
            password: '',
            mobileNumber: '',
            email: '',
            selectedPartnerIds: '',
            firstName: '',
            lastName: '',
            agentTitle: '',
        };

        if (editedUser.email.trim() === '') {
            updatedErrors.email = 'Email is required';
            isValid = false;
        } else if (!isValidEmail(editedUser.email)) {
            updatedErrors.email = 'Invalid email format';
            isValid = false;
        }

        if (editedUser.password && editedUser.password.trim() === '') {
            updatedErrors.password = 'Password is required';
            isValid = false;
        }

        if (editedUser.mobileNumber.trim() === '') {
            updatedErrors.mobileNumber = 'Mobile number is required';
            isValid = false;
        } else if (!isValidMobileNumber(editedUser.mobileNumber)) {
            updatedErrors.mobileNumber = 'Invalid mobile number';
            isValid = false;
        }

        setErrors(updatedErrors);
        return isValid;
    };

    const handleSave = () => {
      if (validateFields()) {
        onSave(editedUser, isAdmin);
        setEditedUser(editedUser);
      }
    };

    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isValidMobileNumber = (mobileNumber: string) => {
        return  /^\+?(\d{1,3})?[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(mobileNumber);
    };

    const handleAdminSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setIsAdmin(checked);
        const updatedPartnerMappings = user.partnerMappings.map(mapping => ({
            ...mapping,
            isPartnerAdmin: checked
        }));
        setEditedUser({...editedUser});
    };

    const handleRefresh = () => {   
        setEditedUser(initialEditedUserState);
        setIsAdmin(user.partnerMappings[0].isPartnerAdmin);
    };
    
    return (
        <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
            <Box className={classes.gridControlsContainer}>
                <Typography className={classes.editHeader}>Access: {editedUser.firstName} {editedUser.lastName}</Typography>
                <Box className={classes.buttonContainer}>
                    <IconButton onClick={handleRefresh} className='clear-button'>
                        REFRESH
                    </IconButton>
                    <IconButton onClick={onCancel} className='clear-button'>
                        CANCEL
                    </IconButton>
                    <IconButton onClick={handleSave} className='save-button'>
                        SAVE
                    </IconButton>
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        className={classes.textField}
                        label="First name"
                        value={editedUser.firstName}
                        onChange={(e) => handleInputChange('firstName', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        className={classes.textField}
                        label="Last name"
                        value={editedUser.lastName}
                        onChange={(e) => handleInputChange('lastName', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        error={!!errors.email}
                        helperText={errors.email}
                        className={classes.textField}
                        disabled
                        label="Email address"
                        value={editedUser.email}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        className={classes.textField}
                        label="Title"
                        value={editedUser.agentTitle}
                        onChange={(e) => handleInputChange('agentTitle', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        error={!!errors.mobileNumber}
                        helperText={errors.mobileNumber}
                        className={classes.textField}
                        label="Mobile phone number"
                        value={editedUser.mobileNumber}
                        onChange={(e) => handleInputChange('mobileNumber', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        select
                        className={classes.textField}
                        label="Status"
                        value={editedUser.userStatus}
                        onChange={(e) => handleInputChange('userStatus', e.target.value)}
                        fullWidth
                    >
                        {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControlLabel
                        control={<Switch checked={isAdmin} onChange={handleAdminSwitchChange} />}
                        label="Administrator"
                    />
                </Grid>
            </Grid>
            {serverError && (
                <Typography variant="body2" color="error">
                    {serverError}
                </Typography>
            )}
        </form>
    );
};

export default EditUserForm;

