import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, TextField, Grid, IconButton, Box, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import EditUserForm from './EditUserForm';
import AddUserForm from './AddUserForm';
import UserListGrid from './UserListGrid';
import { User, AllUsersData, EditUserRequest } from '../types/types';
import { UpdateDashboardUser, CreateDashboardUser, ReAuthenticate } from '../services/DashboardServiceList';
import Loading from './Loading';

  const useStyles = makeStyles((theme) => ({
    modalContainer: {
      backgroundColor: '#fff',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3),
      borderRadius: '8px',
      maxWidth: '1200px',
      minHeight: '550px',
      margin: 'auto',
      marginTop: '100px',
      position: 'relative',
    },
    modalHeader: {
      color: '#063056',
      textAlign: 'left',
      fontSize: '24px',
      fontWeight: 700,
      marginBottom: '20px',
    },
    searchBar: {
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      marginBottom: theme.spacing(0),
    },
    gridItem: {
      padding: theme.spacing(1),
    },
    form: {
      width: '100%',
    },
    textField: {
      marginBottom: theme.spacing(2),
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
          },
        },
      },
      '& .MuiSelect-select.MuiSelect-select': {
        color: 'black',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      cursor: 'pointer',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    searchButtonContainer: {
      display: 'flex',
    },
    searchContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    gridControlsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      marginBottom: '20px',
    },
    searchField: {
      marginRight: 'auto',
      minWidth: '100%',
      border: '1px solid #DBE2EA',
      borderRadius: '30px',
      '& .MuiInputAdornment-positionStart': {
        margin: '15px',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '30px',
      },
      '&: .MuiInputBase-input': {
        padding: '6px 0 7px !important',
        background: '#FFF',
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px',
        borderRadius: '3px',
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '5px',
      },
      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '5px',
      },
      '& .MuiSvgIcon-root': {
        fill: 'rgba(0, 0, 0, 0.54)',
      },
    },
    activeSearch: {
      marginRight: 'auto',
      minWidth: '100%',
      border: '1px solid #0F4C82',
      borderRadius: '30px',
      background: '#0F4C82',
      '& .MuiOutlinedInput-root': {
        borderRadius: '30px',
      },
      '&: .MuiInputBase-input': {
        padding: '6px 0 7px !important',
      },
      '& .MuiSvgIcon-root': {
        fill: '#FFF',
      },
      '& .MuiOutlinedInput-input': {
        background: '#DBE2EA !important',
        borderRadius: '0px',
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '5px',
      },
      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '5px',
      },
    },
  }));

interface PartnerSettingsModalProps {
  userData?: User | null;
  allUserData?: AllUsersData | null;
  onClose: () => void;
  refreshUserGrid: () => void;
  selectedPartnerId: any;
}

const statusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Archived', label: 'Archived' },
];

type ServerError = string | null;

const PartnerSettingsModal: React.FC<PartnerSettingsModalProps> = ({ userData, allUserData, onClose, refreshUserGrid, selectedPartnerId }) => {
  const classes = useStyles();
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<string>('');
  const [isAddUserVisible, setIsAddUserVisible] = useState(false);
  const [serverError, setServerError] = useState<ServerError>(null);

  const firstPartnerId = userData?.partnerMappings[0]?.partnerId || '';
  const firstPartnerName = userData?.partnerMappings[0]?.partnerName || '';
  const firstPartnerisAdmin = userData?.partnerMappings[0]?.isPartnerAdmin || false;
  const [partnerId, setPartnerId] = useState<string>(firstPartnerId);

  useEffect(() => {
    if (userData) {
      setEditingUser(userData);
      setIsAdmin(userData.partnerMappings.some(mapping => mapping.isPartnerAdmin));
      const currentStatus = userData.userStatus;
      setCurrentStatus(currentStatus);
    }
  }, [userData]);

  const handleEditUser = (user: User) => {
    setServerError('');
    setEditingUser(user);
    setIsEditVisible(true);
  };

  const handleShowAddUserForm = () => {
    setServerError('');
    setIsAddUserVisible(true);
  };

  const handleHideAddUserForm = () => {
    setIsAddUserVisible(false);
  };

  const retryAfterReauthentication = async (token:any, requestBody:any, action:any) => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('Refresh token not found');
    }
    const reAuthResponse = await ReAuthenticate(token, refreshToken);
    localStorage.setItem('accessToken', JSON.stringify(reAuthResponse.accessToken));
    await action();
  };

  const handleAddUser = async (userData: any, isNewUserAdmin: boolean) => {
    const requestBody: any = {
      userStatus: userData.userStatus,
      password: userData.password,
      firstName: userData.firstName,
      lastName: userData.lastName,
      mobileNumber: userData.mobileNumber,
      email: userData.email,
      avatarUrl: 'string',
      agentDisplayName: 'string',
      agentTitle: userData.agentTitle,
      selectedPartnerIds: {
        [selectedPartnerId]: isNewUserAdmin
      }
    };  
    const token: any = localStorage.getItem('accessToken');
    let response;
    try {
      response = await CreateDashboardUser(token, requestBody, setServerError);   
      setIsAddUserVisible(false);
      refreshUserGrid();
    } catch (error: any) {
      if (error.status === 401) {
        try {
          await retryAfterReauthentication(token, requestBody, () => handleAddUser(userData, isNewUserAdmin));
        } catch (reAuthError:any) {
          handleRefresh();
        }
      } else {
        setServerError(`Dashboard User Already exists`);
      }
    }
  };

  const handleSaveEdit = async (userData: any, isNewUserAdmin: boolean) => {
   const requestBody: EditUserRequest = {
    userStatus: userData.userStatus,
    password: userData.password,
    firstName: userData.firstName,
    lastName: userData.lastName,
    mobileNumber: userData.mobileNumber,
    email: userData.email,
    avatarUrl: 'string',
    agentDisplayName: 'string',
    agentTitle: userData.agentTitle,
    selectedPartnerIds: {
      [selectedPartnerId]: isNewUserAdmin
    },
    id: userData.id
  };

    const token = localStorage.getItem('accessToken');
    try {
      if (!editingUser) return;

      const updatedUserData: User = {
        ...editingUser,
        userStatus: currentStatus,
        partnerMappings: editingUser.partnerMappings.map(mapping => ({
          ...mapping,
          isPartnerAdmin: isAdmin
        }))
      };
      await UpdateDashboardUser(token, requestBody);
      setIsEditVisible(false);
      refreshUserGrid();
    } catch (error) {
      try {
        await retryAfterReauthentication(token, requestBody, () => handleSaveEdit(userData, isNewUserAdmin));
      } catch (reAuthError) {
        console.error('Error re-authenticating:', reAuthError);
        handleRefresh();
      }
    }
  };

  const handleCancel = () => {
    setIsEditVisible(false);
  };

  const handleRefresh = () => {
    if (userData) {
      setEditingUser(userData);
      setIsAdmin(userData.partnerMappings.some(mapping => mapping.isPartnerAdmin));
      const currentStatus = userData.userStatus;
      setCurrentStatus(currentStatus);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleGridClearSearch = () => {
    setSearchTerm('');
  };
  
  const filteredUserData = allUserData?.pageResults.filter(
    user =>
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.mobileNumber.includes(searchTerm)
  ) || [];

  const handleInputChange = (field: keyof User, value: string | boolean) => {
    if (editingUser) {
      setEditingUser({
        ...editingUser,
        [field]: value,
      });
    }
  };

  return (
    <div className={classes.modalContainer}>
      <IconButton className={classes.closeIcon} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    <Typography variant="h5" gutterBottom className={classes.modalHeader}>
      ACCESS PERMISSIONS
    </Typography>
    {isAddUserVisible && (
      <AddUserForm onSave={handleAddUser}
        onCancel={() => setIsAddUserVisible(false)}
        statusOptions={statusOptions} 
        onSuccess={handleRefresh}
        serverError={serverError}
      />
    )}
    {isEditVisible && editingUser && (
      <EditUserForm
          user={editingUser}
          onSave={handleSaveEdit}
          onCancel={handleCancel}
          statusOptions={statusOptions}
          onRefresh={handleRefresh} 
      />
    )}
    {!isEditVisible && !isAddUserVisible && (
      <>
        <div className={classes.gridControlsContainer}>
          <Box display="flex" alignItems="center">
            <TextField
              label=""
              variant="outlined"
              placeholder='Search using any attribute below'
              name='user-search'
              className={`${classes.searchField} ${searchTerm  ? classes.activeSearch : ''}`}
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  searchTerm && (
                    <IconButton onClick={handleGridClearSearch} title='Remove Search'>
                      <ClearIcon />
                    </IconButton>
                  )
                ),
              }}
            />      
          </Box>
          <Box display="flex" alignItems="center" className={classes.searchButtonContainer}>
            <IconButton className='clear-button' onClick={handleShowAddUserForm}>
              <AddIcon /> 
              <Typography variant="body1">Add</Typography>
            </IconButton>
            <IconButton onClick={onClose} className={classes.closeIcon}>
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
        <Grid container spacing={1} className={classes.gridContainer}>
           {allUserData ? (
            <>
              <UserListGrid userData={{...allUserData, pageResults: filteredUserData}} onEditUser={handleEditUser}  />
            </>
          ) : (
            <Loading column={7} />
          )}
        </Grid>
      </>
    )}
  </div>
  );
};

export default PartnerSettingsModal;