import React from 'react';
import { Grid, makeStyles, IconButton } from '@material-ui/core';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@material-ui/icons';
import { KeyboardDoubleArrowLeftOutlined, KeyboardDoubleArrowRightOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '15px',
  },
  pageNumbers: {
    display: 'flex',
    alignItems: 'center',
    gap: '0px',
    padding: '5px !important',
    color: '#0F4C82',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  pageNumber: {
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
    padding: '8px 12px',
    border: '1px solid #fff',
    '&.selected': {
        background: '#E7EDF3',
        borderRadius: '3px',
        padding: '8px 12px',
        border: '1px solid #C8D2DF',
        '&:hover': {
            background: '#E7EDF3',
            borderRadius: '3px',
            border: '1px solid #C8D2DF',
            padding: '8px 12px',
        },
    },
    '&:hover': {
        background: '#fff',
        borderRadius: '3px',
        border: '1px solid #C8D2DF',
        padding: '8px 12px',
    },
  },
}));

const Pagination: React.FC<{
  totalCount: number;
  page: number;
  currentPage: number,
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}> = ({ totalCount, currentPage, rowsPerPage, onChangePage }) => {
  const classes = useStyles();

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, currentPage - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, currentPage + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(totalCount / rowsPerPage) - 1));
  };

  const totalPages = Math.ceil(totalCount / rowsPerPage);
  const startPage = Math.max(0, Math.min(currentPage - 4, totalPages - 10));
  const visiblePageNumbers = Array.from({ length: Math.min(10, totalPages - startPage) }, (_, index) => startPage + index);

  return (
    <Grid container className={classes.pagination}>
      <Grid item>
        <div>
          <IconButton onClick={handleFirstPageButtonClick}  className='page-arrow' disabled={currentPage === 0}>
            <KeyboardDoubleArrowLeftOutlined/>
          </IconButton>
          <IconButton onClick={handleBackButtonClick} className='page-arrow' disabled={currentPage === 0}>
            <KeyboardArrowLeftOutlined />
          </IconButton>
        </div>
      </Grid>
      <Grid item className={classes.pageNumbers}>
        {visiblePageNumbers.map((pageNumber) => (
          pageNumber === currentPage ?
          <span key={pageNumber} className={`${classes.pageNumber} ${pageNumber === currentPage ? 'selected' : ''}`} >
            {pageNumber + 1}
          </span> : <span key={pageNumber} className={`${classes.pageNumber} ${pageNumber === currentPage ? 'selected' : ''}`} onClick={() => onChangePage(null, pageNumber)}>
            {pageNumber + 1}
          </span>
        ))}
      </Grid>
      <Grid item>
        <div>
          <IconButton onClick={handleNextButtonClick} className='page-arrow' disabled={currentPage >= totalPages - 1}>
            <KeyboardArrowRightOutlined />
          </IconButton>
          <IconButton onClick={handleLastPageButtonClick} className='page-arrow' disabled={currentPage >= totalPages - 1}>
            <KeyboardDoubleArrowRightOutlined/>
          </IconButton>
        </div>
      </Grid>
    </Grid>
  );
};

export default Pagination;
