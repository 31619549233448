import React from 'react';
import { AppBar, Toolbar, Typography, Button, makeStyles } from '@material-ui/core';
import footerLogoImage from '../images/Powered_by_AskVet_rev.svg';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: '0',
    padding: '10px 0',
  },
  logo: {
    marginLeft: theme.spacing(2),
    width: 140,
    height: 40,
    backgroundImage: `url(${footerLogoImage})`,
    backgroundRepeat: 'no-repeat',
    flexGrow: 1,
  },
  contactButton: {
    color: theme.palette.common.white,
    border: '0',
  },
}));

const PageFooter: React.FC = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.footer}>
      <Toolbar>
        <Typography className={classes.logo}></Typography>
        <Button
          variant="outlined"
          color="inherit"
          className={classes.contactButton}
        >
          <Button component="a" href="https://salesops@ask.vet" target="_blank">
            Contact Us
          </Button>
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default PageFooter;