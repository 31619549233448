import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';

interface LoadingProps {
  column: number;
}

const Loading: React.FC<LoadingProps> = ({column}) => {
  return (
    <>
      {[...Array(10)].map((_, rowIndex) => (
        <TableRow key={rowIndex} className='loading-row'>
          {[...Array(column)].map((_, colIndex) => (
            <TableCell key={colIndex} className={`loading-cell loading-cell-${column}`} />
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default Loading;