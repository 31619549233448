import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, TextField, Grid, MenuItem, Switch, FormControlLabel, IconButton, Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AddUserRequest } from '../types/types';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    textField: {
        marginBottom: theme.spacing(2),
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                },
            },
        },
        '& .MuiSelect-select.MuiSelect-select': {
            color: 'black',
        },
    },
    gridControlsContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        marginTop: '40px',
        marginBottom: '40px',
        fontSize: '24px',
        fontWeight: 300,
    },
    editHeader: {
        fontSize: '24px',
        fontWeight: 300,
        display: 'flex',
        color: '#231F20',
    }
}));

interface AddUserFormProps {
    onSave: (newUser: AddUserRequest, isAdmin: boolean) => void;
    onCancel: () => void;
    statusOptions: { value: string; label: string }[];
    onSuccess: () => void;
    serverError: any; 
}

const AddUserForm: React.FC<AddUserFormProps> = ({
        onSave,
        onCancel,
        statusOptions,
        serverError
    }) => {
    const classes = useStyles();

    const initialUserState: AddUserRequest = {
      userStatus: 'Active',
      password: '',
      firstName: '',
      lastName: '',
      mobileNumber: '',
      email: '',
      avatarUrl: '',
      agentDisplayName: '',
      agentTitle: '',
      selectedPartnerIds: {},
    };

    const [newUser, setNewUser] = useState<AddUserRequest>(initialUserState);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [initialUserData, setInitialUserData] = useState<AddUserRequest>({} as AddUserRequest);

    useEffect(() => {
        setInitialUserData({ ...newUser });
    }, []);
    
    const [errors, setErrors] = useState<{ [key in keyof AddUserRequest]: string }>({
      userStatus: 'Active',
      email: '',
      password: '',
      mobileNumber: '',
      selectedPartnerIds: '',
    });

    const formatPhoneNumber = (input:string): string => {
        const digits = input.replace(/\D/g, "");
        const match = digits.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/);
        if (match) {
            const [, areaCode, middle, last] = match;
            return `${areaCode ? `(${areaCode}` : ''}${middle ? `) ${middle}` : ''}${last ? `-${last}` : ''}`;
        }
        return input;
    };

    const handleInputChange = (field: keyof AddUserRequest, value: string) => {
        const formattedValue = field === 'mobileNumber' ? formatPhoneNumber(value) : value;

        setNewUser({
            ...newUser,
            [field]: formattedValue,
        });
        setErrors({
            ...errors,
            [field]: '',
        });
    };

    const validateFields = () => {
        let isValid = true;
        const updatedErrors: { [key in keyof AddUserRequest]: string } = {
            userStatus: '',
            password: '',
            mobileNumber: '',
            email: '',
            selectedPartnerIds: '',
        };

        if (!newUser.email.trim()) {
            updatedErrors.email = 'Email is required';
            isValid = false;
        } else if (!isValidEmail(newUser.email)) {
            updatedErrors.email = 'Invalid email format';
            isValid = false;
        }

        if (!newUser.mobileNumber.trim()) {
            updatedErrors.mobileNumber = 'Mobile number is required';
            isValid = false;
        } else if (!isValidMobileNumber(newUser.mobileNumber)) {
            updatedErrors.mobileNumber = 'Invalid mobile number';
            isValid = false;
        }

        if (!validatePassword(newUser.password)) {
            updatedErrors.password = 'Password must be at least 6 characters, have at least one non-alphanumeric character, and have at least one digit.';
            if (!updatedErrors.password) {
                isValid = false;
            }
        }

        setErrors(updatedErrors);
        return isValid;
    };

    const handleSave = async () => {
      if (validateFields()) {
        try {
            await onSave(newUser, isAdmin);
            setInitialUserData({ ...newUser });
        } catch (error) {
            setNewUser({ ...initialUserData });
            setErrors(serverError)
        }
      }
    };

    const isValidEmail = (email: string) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{6,}$/;
        return passwordRegex.test(password);
    };

    const isValidMobileNumber = (mobileNumber: string) => {
        return  /^\+?(\d{1,3})?[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(mobileNumber);
    };

    const handleAdminSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsAdmin(event.target.checked);
      const updatedSelectedPartnerIds = { ...newUser.selectedPartnerIds };
      Object.keys(updatedSelectedPartnerIds).forEach(partnerId => {
          updatedSelectedPartnerIds[partnerId] = isAdmin;
      });
      setNewUser({
          ...newUser,
          selectedPartnerIds: updatedSelectedPartnerIds,
      });
    };

    const resetErrors = () => {
        setErrors({
            userStatus: '',
            email: '',
            password: '',
            mobileNumber: '',
            selectedPartnerIds: '',
        });
    };

    const handleRefresh = () => {
        resetErrors();
        setNewUser(initialUserState);
        setIsAdmin(false);
    };

    return (
        <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
            <Box className={classes.gridControlsContainer}>
                <Typography className={classes.editHeader}>Access: {newUser.firstName} {newUser.lastName}</Typography>
                <Box className={classes.buttonContainer}>
                    <IconButton onClick={handleRefresh} className='clear-button'>
                        REFRESH
                    </IconButton>
                    <IconButton onClick={onCancel} className='clear-button'>
                        CANCEL
                    </IconButton>
                    <IconButton onClick={handleSave} className='save-button'>
                        SAVE
                    </IconButton>
                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        className={classes.textField}
                        label="First name"
                        value={newUser.firstName}
                        onChange={(e) => handleInputChange('firstName', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        className={classes.textField}
                        label="Last name"
                        value={newUser.lastName}
                        onChange={(e) => handleInputChange('lastName', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        error={!!errors.email}
                        helperText={errors.email}
                        className={classes.textField}
                        label="Email address"
                        value={newUser.email}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                <TextField
                    error={!!errors.password}
                    helperText={errors.password}
                    className={classes.textField}
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={newUser.password}
                    onChange={(e) => handleInputChange('password', e.target.value)}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                />               
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        className={classes.textField}
                        label="Title"
                        value={newUser.agentTitle}
                        onChange={(e) => handleInputChange('agentTitle', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        error={!!errors.mobileNumber}
                        helperText={errors.mobileNumber}
                        className={classes.textField}
                        label="Mobile phone number"
                        value={newUser.mobileNumber}
                        onChange={(e) => handleInputChange('mobileNumber', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        select
                        className={classes.textField}
                        label="Status"
                        value={newUser.userStatus}
                        disabled
                        onChange={(e) => handleInputChange('userStatus', e.target.value)}
                        fullWidth
                    >
                        {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControlLabel
                        control={<Switch checked={isAdmin} onChange={handleAdminSwitchChange} />}
                        label="Administrator"
                    />
                </Grid>
            </Grid>
            {serverError && (
                <Typography variant="body2" color="error">
                    {serverError}
                </Typography>
            )}
        </form>
    );
};

export default AddUserForm;

