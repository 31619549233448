import React, { useState } from 'react';
import { makeStyles, Grid, Table, TableHead, TableRow, TableCell, Tooltip, TableBody, Switch, Link } from '@material-ui/core';
import { User, AllUsersData } from '../types/types';
import Pagination from './Pagination';

  interface UserListGridProps {
    userData: AllUsersData;
    onEditUser: (user: User) => void;
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      height: '675px',
      overflow: 'auto',
    },
    header: {
      display: 'flex',
      width: '100%',
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      borderBottom: '1px solid #e0e0e0',
    },
    link: {
      color: '#0F4C82 !important',
      cursor: 'pointer',
      fontWeight: 700,
      '&:hover': {
        textDecoration: 'underlined',
      }
    },
    row: {
      borderBottom: '1px solid #e0e0e0',
      padding: theme.spacing(2),
      display: 'flex',
      minWidth: '100%',
      justifyContent: 'space-between',
    },
    modalContainer: {
      backgroundColor: '#fff',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3),
      borderRadius: '8px',
      maxWidth: '1200px',
      margin: 'auto',
      marginTop: '100px',
      marginBottom: '5px !important',
      position: 'relative',
    },
    modalHeader: {
      color: '#063056',
      textAlign: 'left',
      fontSize: '24px',
      fontWeight: 700,
      marginBottom: '20px',
    },
    searchBar: {
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      marginBottom: '0px !important',
      justifyContent: 'center',
    },
    gridItem: {
      padding: theme.spacing(1),
    },
    form: {
      width: '100%',
    },
    textField: {
      marginBottom: theme.spacing(2),
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black',
          },
        },
      },
      '& .MuiSelect-select.MuiSelect-select': {
        color: 'black',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      cursor: 'pointer',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    searchButtonContainer: {
      display: 'flex',
    },
    searchContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    gridControlsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
    searchField: {
      marginRight: 'auto',
      minWidth: '100%',
      border: 0,
    },
    tableBody: {
     
    },
    pagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '0px',
    },
    pageNumbers: {
      display: 'flex',
      alignItems: 'center',
      gap: '0px',
      padding: '5px !important',
      color: '#0F4C82',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    pageNumber: {
      cursor: 'pointer',
      margin: theme.spacing(0, 1),
      padding: '8px 12px',
      border: '1px solid #fff',
      '&.selected': {
          background: '#E7EDF3',
          borderRadius: '3px',
          padding: '8px 12px',
          border: '1px solid #C8D2DF',
          '&:hover': {
              background: '#E7EDF3',
              borderRadius: '3px',
              border: '1px solid #C8D2DF',
              padding: '8px 12px',
          },
      },
      '&:hover': {
          background: '#fff',
          borderRadius: '3px',
          border: '1px solid #C8D2DF',
          padding: '8px 12px',
      },
    },
  }));

  const UserListGrid: React.FC<UserListGridProps> = ({ userData, onEditUser }) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(0);
    };

    const filteredUsers = userData.pageResults.filter(user =>
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const formatPhoneNumber = (phoneNumberString:string) => {
      if (!phoneNumberString) return '-';
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return phoneNumberString; // return original on no match
    }

    return (
      <Grid container spacing={1} className={classes.gridContainer}>
        <div className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>Email address</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Partner</TableCell>
                <TableCell>Administrator</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {(rowsPerPage > 0
                ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : filteredUsers
              ).map((user) => (
                <TableRow key={user.id} onClick={() => onEditUser(user)}>
                  <TableCell title='Edit'><Link className={classes.link}>{user.firstName == '' && user.lastName == '' ? '-' : user.fullName}</Link></TableCell>
                  <TableCell>{formatPhoneNumber(user.mobileNumber) || '-'}</TableCell>
                  <TableCell>{user.email || '-'}</TableCell>
                  <TableCell>{user.userStatus || '-'}</TableCell>
                  <TableCell>{user.agentTitle || '-'}</TableCell>
                  <TableCell>
                    <Tooltip title={user.partnerMappings && user.partnerMappings.length > 0 ? user.partnerMappings.map(mapping => mapping.partnerName).join(', ') : '-'} arrow>
                      <div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer' }}>
                      {user.partnerMappings && user.partnerMappings.length > 0 ? user.partnerMappings.map(mapping => mapping.partnerName).join(', ') : '-'}
                      </div>
                    </Tooltip> 
                  </TableCell>
                  <TableCell>
                    <Switch checked={user.partnerMappings[0]?.isPartnerAdmin || false} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          
        </div>
        <Pagination
            totalCount={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            currentPage={page}
            page={page}
            onChangePage={handleChangePage}
          />
      </Grid>
    );
};

export default UserListGrid;