import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, Typography, Button, makeStyles, MenuItem, Menu, Modal, Backdrop, Fade
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ReportButton from './ReportButton';
import SettingsIcon from '@material-ui/icons/Settings';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PartnerSettingsModal from './PartnerSettingsModal';
import { FetchDashboardUsers, ReAuthenticate, SetTimeZone } from '../services/DashboardServiceList';
import { User, AllUsersData } from '../types/types';

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginBottom: theme.spacing(0),
    background: 'transparent',
    boxShadow: 'none',
    paddingTop: '20px',
    backgroundColor: 'transparent',
  },
  toolBar: {
    gap: '10px',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    height: '90px',
  },
  dashboardName: {
    color: '#fff',
    fontFamily: 'KreonSemiBold, sans-serif',
    fontSize: '35px',
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  brand: {
    color: '#ECA945',
    fontFamily: 'KreonSemiBold, sans-serif',
    fontSize: '35px',
    minWidth: '0px !important',
    textAlign: 'left',
  },
  logOutButton: {
    marginLeft: theme.spacing(0),
    background: 'transparent',
    boxShadow: 'none',
    fontSize: '20px',
    display: 'inline-flex',
    flexFlow: 'row-reverse',
    gap: '15px',
  },
  logoutIcon: {
    fontSize: '40px !important',
  },
  logoutText: {
    display: 'inline-flex',
    flexFlow: 'row-reverse',
    gap: '15px',
    fontSize: '20px',
  },
  dropdownArrow: {
    color: '#ECA945',
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s ease',
  },
  dropdownArrowOpen: {
    transform: 'rotate(180deg)',
  },
  gearIcon: {
    color: '#ECA945',
    marginRight: theme.spacing(2),
  },
  modal: {},
  settingsIcon: {
    fontSize: '40px !important',
    color: '#fff',
    marginRight: '0px',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#ECA945',
    },
  },
  formControl: {
    minWidth: 200,
    marginLeft: theme.spacing(2),
  },
  timeZoneButton: {
    color: '#FFF',
    fontSize: '20px',
    minWidth: '0px !important',
  },
  timeZoneIcon: {
    fontSize: '40px !important',
    margin: '0 5px 0px 15px',
  },
}));

const US_TIME_ZONES = [ 
  {value: 'Alaskan Standard Time', label: 'Alaska Standard Time'},
  {value: 'Atlantic Standard Time', label: 'Atlantic Standard Time'},
  {value: 'Australian Eastern Standard Time', label: 'Australian Eastern Standard Time'},
  {value: 'Australian Central Standard Time', label: 'Australian Central Standard Time'},
  {value: 'Australian Western Standard Time', label: 'Australian Western Standard Time'},
  {value: 'Central Standard Time', label: 'Central Standard Time'},
  {value: 'Pacific/Guam', label: 'Chamorro Standard Time'},
  {value: 'Eastern Standard Time', label: 'Eastern Standard Time'},
  {value: 'Hawaiian Standard Time', label: 'Hawaii-Aleutian Standard Time'},
  {value: 'Mountain Standard Time', label: 'Mountain Standard Time'},
  {value: 'Pacific Standard Time', label: 'Pacific Standard Time'},
  {value: 'Philippine Time', label: 'Philippine Time'},
  {value: 'Samoa Standard Time', label: 'Samoa Standard Time'} 
];

const displayFormattedZoneLabel = (value: string) => {
  if (value === 'Pacific/Guam') {
    return 'Chamorro Standard Time';
  } else if (value === 'Alaskan Standard Time') {
    return 'Alaska Standard Time';
  } else if (value === 'Hawaiian Standard Time') {
    return 'Hawaii-Aleutian Standard Time';
  } else {
    return value;
  } 
}

interface PageHeaderProps {
  onLogout: () => void;
  onPartnerChange: (partnerId: string, partnerName: string) => void;
  isLoggedIn?: boolean;
  onOpenReportDialog: () => void;
  userData?: User | null;
  allUserData?: AllUsersData;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  onLogout, isLoggedIn, onOpenReportDialog, userData, allUserData, onPartnerChange
}) => {
  const classes = useStyles();

  const firstPartnerId = userData?.partnerMappings[0]?.partnerId || null;
  const firstPartnerName = userData?.partnerMappings[0]?.partnerName || null;
  const firstPartnerisAdmin = userData?.partnerMappings[0]?.isPartnerAdmin || false;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [timeZoneAnchorEl, setTimeZoneAnchorEl] = useState<null | HTMLElement>(null);

  const storedPartnerId = localStorage.getItem('partnerId');
  const storedPartnerName = localStorage.getItem('partnerName');

  const [selectedPartnerId, setSelectedPartnerId] = useState<string | null>(storedPartnerId);
  const [selectedPartner, setSelectedPartner] = useState<string | null>(storedPartnerName);
  const [isPartnerAdmin, setIsPartnerAdmin] = useState<boolean>(firstPartnerisAdmin);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [allUser, setAllUser] = useState<AllUsersData | null>(null);

  const firstTimeZone = userData?.timeZone || 'Pacific Standard Time';
  const storedTimeZone = localStorage.getItem('timeZone');

  const storedTimeZoneLabel = localStorage.getItem('timeZoneLabel');

  const [selectedTimeZone, setSelectedTimeZone] = useState<string | null>(storedTimeZone);
  const [selectedTimeZoneLabel, setSelectedTimeZoneLabel] = useState<string | null>(storedTimeZoneLabel);


  useEffect(() => {
    const partnerId = storedPartnerId || firstPartnerId;
    const partnerName = storedPartnerName || firstPartnerName;

    setSelectedPartnerId(firstPartnerId === null? null : partnerId);
    setSelectedPartner(firstPartnerName === null ? null : partnerName);

    setSelectedTimeZone(firstTimeZone || storedTimeZone);
    setSelectedTimeZoneLabel(firstTimeZone || storedTimeZoneLabel);

    if (userData) {
      const fetchUserData = async () => {
        try {
          const token = localStorage.getItem('accessToken');
          if (token && selectedPartnerId) {
            const userData = await FetchDashboardUsers(token, selectedPartnerId);
            setAllUser(userData);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          const timezone = userData?.timeZone !== null ? userData?.timeZone : 'Pacific Standard Time';
          localStorage.setItem('timeZone', timezone);
          localStorage.setItem('timeZoneLabel', displayFormattedZoneLabel(timezone));
        }
      };

      fetchUserData();
    }
  }, [userData, selectedPartnerId, selectedPartner]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleTimeZoneMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTimeZoneAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (partnerId: string, partnerName: string, isAdmin: boolean) => {
    setIsPartnerAdmin(isAdmin);
    setSelectedPartnerId(partnerId);
    setSelectedPartner(partnerName);
    onPartnerChange(partnerId, partnerName);
    setAnchorEl(null);
    setIsDropdownOpen(false);
  };

 const handleTimeZoneMenuItemClick = async (timeZone: string, timeZoneLabel: string) => {
    setSelectedTimeZone(timeZone);
    setSelectedTimeZoneLabel(timeZoneLabel);
    localStorage.setItem('timeZone', timeZone);
    localStorage.setItem('timeZoneLabel', timeZoneLabel);

    setTimeZoneAnchorEl(null);

    const token = localStorage.getItem('accessToken');
    const userId = userData?.id;

    if(timeZoneLabel === "Australian Eastern Standard Time" || 
      timeZoneLabel === "Australian Central Standard Time" || 
      timeZoneLabel === "Australian Western Standard Time" || 
      timeZoneLabel === "Philippine Time") {
      return;
    }

    if (token && userId) {
      try {
        await SetTimeZone(token, userId, timeZone);
      } catch (error) {
        console.error('Error setting time zone:', error);
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsDropdownOpen(false);
  };

  const handleTimeZoneClose = () => {
    setTimeZoneAnchorEl(null);
  };

  const handleRefreshUserGrid = async () => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      setAllUser(null);

      try {
        const refreshedUserData = await FetchDashboardUsers(token, selectedPartnerId);
        setAllUser(refreshedUserData);
      } catch (error) {
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          if (!refreshToken) {
            return;
          }

          const reAuthResponse = await ReAuthenticate(token, refreshToken);
          localStorage.setItem('accessToken', reAuthResponse.accessToken);

          const refreshedUserData = await FetchDashboardUsers(reAuthResponse.accessToken, selectedPartnerId);
          setAllUser(refreshedUserData);
        } catch (reAuthError) {
          console.error('Error reauthenticating:', reAuthError);
          setIsModalOpen(false);
          onLogout();
        }
      }
    }
  };

  const handleOpenModal = () => {
    handleRefreshUserGrid();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Typography variant="h6" className={classes.title}>
          {userData && userData.partnerMappings.length > 1 ? (
            <>
              <Button className={classes.brand} aria-controls="partner-menu" aria-haspopup="true" title="Partners" onClick={handleMenuClick}>
                {selectedPartner || firstPartnerName}
                <ArrowDropDownIcon
                  className={`${classes.dropdownArrow} ${isDropdownOpen ? classes.dropdownArrowOpen : ''}`}
                />
              </Button>
              <Menu
                id="partner-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className='dropdownMenu'
              >
                {userData.partnerMappings.map((partner, index) => (
                  <MenuItem key={index} onClick={() => handleMenuItemClick(partner.partnerId, partner.partnerName, partner.isPartnerAdmin)}>
                    {partner.partnerName}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <Button className={classes.brand} title="Partner">
              {firstPartnerName}
            </Button>
          )}
          <Typography className={classes.dashboardName}>Data Dashboard</Typography>
        </Typography>
        {isLoggedIn && userData && (
          <>
            <Button className={classes.timeZoneButton} aria-controls="time-zone-menu" aria-haspopup="true" title="Timezones" onClick={handleTimeZoneMenuClick}>
              {storedTimeZoneLabel} 
              <AccessTimeIcon className={classes.timeZoneIcon} />
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="time-zone-menu"
              anchorEl={timeZoneAnchorEl}
              keepMounted
              open={Boolean(timeZoneAnchorEl)}
              onClose={handleTimeZoneClose}
            >
              {US_TIME_ZONES.map((zone, index) => (
                <MenuItem key={index} onClick={() => handleTimeZoneMenuItemClick(zone.value, zone.label)}>
                 {zone.label}
                </MenuItem>
              ))}
            </Menu>
            {(isPartnerAdmin || firstPartnerisAdmin) && (
              <SettingsIcon titleAccess='Access Permissions' className={classes.settingsIcon} onClick={handleOpenModal} />
            )}
            <ReportButton openReportModal={onOpenReportDialog} title="Reports" />
            <Button onClick={onLogout} className='logOutButton' title='Log Out'>
              <ExitToAppIcon className={classes.logoutIcon} />
              <span className={classes.logoutText}>Log Out</span>
            </Button>
          </>
        )}
      </Toolbar>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <PartnerSettingsModal
            userData={userData}
            allUserData={allUser}
            onClose={handleCloseModal}
            refreshUserGrid={handleRefreshUserGrid}
            selectedPartnerId={selectedPartnerId}
          />
        </Fade>
      </Modal>
    </AppBar>
  );
};

export default PageHeader;
