import React from 'react';
import { Typography, Button } from '@material-ui/core';
import AssignmentIcon from '@mui/icons-material/Assignment';

  interface ReportButtonProps {
    openReportModal: () => void;
    title: string;
  }

  const ReportButton: React.FC<ReportButtonProps> = ({ openReportModal, title }) => {

  return (
    <Button onClick={openReportModal} className='reportsButton' title={title}>
        <Typography className='reportsText'>Reports</Typography>
        <AssignmentIcon className='reportsIcon' />
    </Button>
  );
};

export default ReportButton;